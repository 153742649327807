import { useHandleSignInCallback } from '@logto/react';
import React from 'react';
import ToolsService from '../../Services/ToolsService';

const AuthCallbackProvider = () => {
  const { isLoading } = useHandleSignInCallback(() => {
    // Navigate to root path when finished
    ToolsService.navigateTo('/');
  });

  // When it's working in progress
  if (isLoading) {
    return <div>Processing login...</div>;
  }

  return null;
 }

export default AuthCallbackProvider;
