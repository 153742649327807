import React, { useEffect } from 'react';


import Layout from '../../components/public/Layout';
import { useLogto } from '@logto/react';
import AuthService from '../../Services/AuthService';

const Logout = () => {

  const {signOut} = useLogto();

  useEffect(() => {
    // const redirect = () => {
    //   ToolsService.navigateTo('/login');
    // };



    signOut(`${window.location.origin}/signout`);
    // setTimeout(redirect, 600);
  }, [signOut]);

  const title = "Logout user";

  return (
      <Layout
          title={title}
          forgotPassword={false}
          register={false}
          login={false}
          forgotPasswordLogin={false}
          loading={true}
          children={<></>}
      />
  );
};

export default Logout;
