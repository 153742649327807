import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import thunk from 'redux-thunk'
import reducers from './reducers'
import './index.css'

import reportWebVitals from './reportWebVitals'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import '../node_modules/bootstrap/scss/bootstrap.scss'
import '../node_modules/font-awesome/scss/font-awesome.scss'
import './assets/assets/scss/main.scss'
import './assets/assets/scss/color_skins.scss'
import AuthConfigProvider from './components/Auth/AuthConfigProvider';
import UserProvider from './components/Auth/UserProvider';
import Authorized from './routes/authorized';
import CustomerProvider from './components/Auth/CustomerProvider';
import AuthCheckProvider from './components/Auth/AuthCheckProvider'

// @ts-expect-error window object not defined
window.__DEV__ = true

const store = configureStore({
  reducer: reducers,
  middleware: [thunk],
  devTools: true
})

// Set user theme
document.body.classList.add('theme-cyan')

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <Provider store={store}>
          <AuthConfigProvider>
            <AuthCheckProvider>
              <UserProvider>
                <CustomerProvider>
                  <BrowserRouter>
                    <Authorized />
                  </BrowserRouter>
                </CustomerProvider>
              </UserProvider>
            </AuthCheckProvider>
          </AuthConfigProvider>
        </Provider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
