import currency from 'currency.js'

class CurrencyService {
  public static format (price: number | bigint | string): string {
    // @ts-expect-error
    return currency(price, { separator: '.', decimal: ',', precision: 2, symbol: '€' }).format()
  }
}

export default CurrencyService
