import React from 'react'
import { Button } from 'react-bootstrap'
import DnsRecordData from '../../Models/Dns/DnsRecordData'

interface PropTypes {
  onSubmit: (val: DnsRecordData) => any
}

interface StateTypes {
  type: string
  name: string
  value: string
}
class DnsRecordForm extends React.Component<PropTypes, StateTypes> {
  constructor (props: PropTypes) {
    super(props)

    this.state = {
      name: '',
      type: '',
      value: ''
    }
  }

  render (): JSX.Element {
    return (
        <>
          <label>Type: </label>
          <div className="input-group mb-3">
            <input
                name="type"
                aria-describedby="basic-addon1"
                aria-label="type"
                className="form-control"
                placeholder="type"
                type="text"
                onChange={(event) => this.setState({ type: event.target.value })}
            />
          </div>
          <label>Name: </label>
          <div className="input-group mb-3">
            <input
                name="name"
                id="name"
                aria-describedby="basic-addon1"
                aria-label="name"
                className="form-control"
                placeholder="Name"
                type="text"
                onChange={(event) => this.setState({ name: event.target.value })}
            />
          </div>
          <label>Value: </label>
          <div className="input-group mb-3">
            <input
                name="value"
                id="value"
                aria-describedby="basic-addon1"
                aria-label="value"
                className="form-control"
                placeholder="Value"
                title="No spaces or special characters allowed"
                type="text"
                onChange={(event) => this.setState({ value: event.target.value })}
            />
          </div>
          <Button onClick={() => this.props.onSubmit({
            type: this.state.type,
            name: this.state.name,
            dnsZone: undefined,
            value: this.state.value,
            priority: '10'
          })} variant="primary">
            Submit
          </Button>
        </>
    )
  }
}

export default DnsRecordForm
