import MenuStructure from '../Models/MenuStructure'
import FeatureFlagService from './FeatureFlagService'
import UserData from '../Models/UserData'

class NavigationService {
  private readonly featureFlagService: FeatureFlagService

  constructor () {
    this.featureFlagService = new FeatureFlagService()
  }

  public static getActiveMenuItem (menuItems: MenuStructure[]): {
    activeMenuItem: MenuStructure | null
    activeParentMenuItem: MenuStructure | null
  } {
    let activeMenuItem: MenuStructure | null = null
    let activeParentMenuItem: MenuStructure | null = null

    menuItems
      .filter((menuItem: MenuStructure) => menuItem.active)
      .forEach((menuItem: MenuStructure) => {
        activeParentMenuItem = menuItem
        activeMenuItem = menuItem

        if (menuItem.children !== null && menuItem.children.length > 0) {
          menuItem.children.forEach((childMenuItem: MenuStructure) => {
            if (menuItem.active) {
              activeMenuItem = childMenuItem
              return true
            }
          })
        }
      })

    if (activeMenuItem === null) {
      return { activeMenuItem: null, activeParentMenuItem: null }
    }

    return {
      activeMenuItem,
      activeParentMenuItem
    }
  }

  public static cleanUrl (url: string) {
    url = url.endsWith('/') ? url.slice(0, -1) : url
    url = url.startsWith('/') ? url.slice(1) : url
    return url.toLowerCase()
  }

  public static generateRouteUrl (url: string | null, routeName: string, parent: MenuStructure | null): string {
    const parentUrl = (parent !== null ? NavigationService.generateRouteUrl(parent.routeUrl, parent.routeName, null) : '')
    let cleanUrl = url

    if (url === null || cleanUrl === null) {
      return parentUrl + '/' + routeName.replace(' ', '').toLowerCase()
    }
    cleanUrl = cleanUrl.startsWith(parentUrl) ? cleanUrl.replace(parentUrl, '') : cleanUrl
    cleanUrl = NavigationService.cleanUrl(cleanUrl)

    return parentUrl + '/' + cleanUrl
  }

  public static createMenuStructureElement (
    routeName: string,
    routeUrl: string | null = null,
    showInMenu: boolean | null = false,
    icon: string = '',
    children: MenuStructure[] | null = null
  ): MenuStructure {
    let childActive = false
    let childShow = false
    const path: string = window.location.pathname
    const active = NavigationService.cleanUrl(path) === NavigationService.cleanUrl(routeUrl ?? '')

    if ((children != null) && children?.length > 0) {
      children.forEach(value => {
        if (value.active) {
          childActive = true
          childShow = true
        }
        if (value.showInMenu ) {
          childShow = true
        }
      })
    }

    return {
      routeName,
      icon,
      routeUrl,
      active: childActive || active,
      showInMenu: childShow || (showInMenu ?? active ?? false),
      children
    }
  }

  public static generateMenuStructure (currentUser: UserData): MenuStructure[] {
    const c = NavigationService.createMenuStructureElement


    const domainChildren = [
      c('My domains', '/dashboard'),
      c('Register new domain', '/buy'),
      c('Check Domain', '/check/', true)
    ]

    const mailChildren = [
      c('Manage mail boxen', '/boxen'),
      c('Register new mail box', '/box'),
      c('Manage mail domains', '/domains'),
      c('Register new mail domain', '/domain')
    ]

    const webHostingChildren = [
      c('Manage sites', '/sites'),
      c('Register new site', '/site')
    ]

    const ordersChildren = [
      c('Manage orders', '/')
    ]

    const financeChildren = [
      c('Manage payments', '/payments'),
      c('Manage invoices', '/invoices/recent', true),
      c('Manage quotes',  '/quotes', true),
      c('Manage orders',  '/orders')
    ]

    const vpsChildren = [
      c('Managed Wordpress', '/vps/wordpress/overview/', true),
      c('Managed Wordpress instance', '/vps/wordpress/details/', null),

      c('VPS servers',  '/vps/generic/overview/', true),
      c('VPS server',  '/vps/generic/details/', null),

      c('Manage VPS servers - linked helper',  '/vps/linked-helper/overview/', FeatureFlagService.checkForVPSFeatureFlag(currentUser)),
      c('Manage VPS server - linked helper',  '/vps/linked-helper/details/', null)
    ]

    return [
      c('Dashboard', '/dashboard', true, 'icon-home', null),
      // c('Domain', null, true, 'icon-grid', domainChildren),
      c('Mail', null, null, 'icon-envelope', mailChildren),
      c('Web hosting', '/web-hosting', null, 'icon-screen-desktop', webHostingChildren),
      // c('Orders', '/orders', null, 'icon-basket', ordersChildren),
      c('Vps', '/vps', true, 'icon-screen-desktop', vpsChildren),
      // c('Finance', null, true, 'icon-tag', financeChildren)
      c('Finance', 'finance/invoices/recent', true, 'icon-tag')
    ]
  }
}

export default NavigationService
