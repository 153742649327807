import React from 'react'
import VpsService from '../../Services/VpsService'
import BaseChart from './BaseChart'

interface PropTypes {
  id: number
  period: string
}

interface StateTypes {
  cpuData: any
  diskData: any
  networkData: any
}
class VpsChart extends React.Component<PropTypes, StateTypes> {
  private readonly vpsService: VpsService

  constructor (props: any) {
    super(props)

    this.state = {
      cpuData: [],
      diskData: [],
      networkData: []
    }

    this.vpsService = new VpsService()

    this.getCpuData = this.getCpuData.bind(this)
    this.getCpuDates = this.getCpuDates.bind(this)
  }

  async getCpuDates (): Promise<any[]> {
    const dates: any[] = []
    return await this.vpsService.getCpuData(this.props.id, this.props.period).then((data: any) => {
      data.time_series.cpu.values.forEach((time: number[]) => {
        const date = new Date(time[0] * 1000)
        dates.push(date.toTimeString().slice(0, 5))
      })
      return (dates)
    })
  }

  async getCpuData (): Promise<any[]> {
    const data: any[] = []
    return await this.vpsService.getCpuData(this.props.id, this.props.period).then((response: any) => {
      response.time_series.cpu.values.forEach((value: string[]) => {
        data.push(parseInt(value[1]))
      })
      return data
    })
  }

  async getDiskData (): Promise<any[]> {
    const data: any[] = []
    return await this.vpsService.getDiskData(this.props.id, this.props.period).then((response: any) => {
      response.time_series['disk.0.bandwidth.read'].values.forEach((value: string[]) => {
        data.push(parseInt(value[1]))
      })
      return (data)
    })
  }

  async getNetworkData (): Promise<any[]> {
    const data: any[] = []
    return await this.vpsService.getNetworkData(this.props.id, this.props.period).then((response: any) => {
      response.time_series['network.0.pps.in'].values.forEach((value: string[]) => {
        data.push(parseInt(value[1]))
      })
      return data
    })
  }

  render (): JSX.Element {
    return (
            <>
                <BaseChart title='CPU' dates={this.getCpuDates()} data={this.getCpuData()}/>
                <BaseChart title='Disk' dates={this.getCpuDates()} data={this.getDiskData()}/>
                <BaseChart title='Network' dates={this.getCpuDates()} data={this.getNetworkData()}/>
            </>
    )
  }
}

export default VpsChart
