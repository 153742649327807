import React from 'react'
import MenuStructure from '../../../Models/MenuStructure'
import NavigationService from '../../../Services/NavigationService'
import { Link } from 'react-router-dom';

function activeMenuTabContainer (id: string): void {
  const parents = document.getElementById('main-menu')
  const activeMenu = document.getElementById(id)

  if (parents === null || activeMenu === null) {
    return
  }

  for (let index = 0; index < parents.children.length; index++) {
    if (parents.children[index].id !== id) {
      parents.children[index].classList.remove('active')

      if(parents.children[index].children[1] !== undefined) {
        parents.children[index].children[1].classList.remove('in')
      }
    }
  }

  setTimeout(() => {
    activeMenu.classList.toggle('active')
    if(activeMenu.children[1] !== undefined) {
      activeMenu.children[1].classList.toggle('in')
    }
  }, 10)
}

function renderChildItem (menuItem: MenuStructure, parentMenuItem: MenuStructure): JSX.Element | null {
  const routeUrl = NavigationService.generateRouteUrl(menuItem.routeUrl, menuItem.routeName, parentMenuItem)
  const key = `${parentMenuItem.routeName}-${menuItem.routeName}`

  if (!menuItem.active && (menuItem.showInMenu === null || !menuItem.showInMenu)) {
    return null
  }

  return (
      <li className={menuItem.active ? 'active' : ''} id={key} key={key}>
        <Link to={routeUrl}>{menuItem.routeName}</Link>
      </li>
  )
}

export default function NavBarSideMenuItem ({ menuItem }: { menuItem: MenuStructure }): JSX.Element | null {
  const routeUrl = NavigationService.generateRouteUrl(menuItem.routeUrl, menuItem.routeName, null)

  if (!menuItem.active && (menuItem.showInMenu === null || !menuItem.showInMenu)) {
    return null
  }

  return (
      <li className={menuItem.active ? 'active' : ''} id={menuItem.routeName} key={menuItem.routeName}>
        <Link to={routeUrl}
            className={menuItem.children === null ? '' : 'has-arrow'}
        >
          <i className={menuItem.icon}></i> <span>{menuItem.routeName}</span>
        </Link>
        {menuItem.children === null
          ? ''
          : (
            <ul className={menuItem.active ? 'collapse in' : 'collapse'}>
              { menuItem.children.map((childMenu: MenuStructure) => {
                return renderChildItem(childMenu, menuItem)
              })}
            </ul>
            )}

      </li>
  )
}
