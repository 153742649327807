import CookieService from './CookieService';

class DarkModeService {
  public static checkForDarkModeFeature (): boolean {
    return !!CookieService.getCookie('darkMode');
  }

  public static setDarkMode (darkMode: boolean): void {
    if (darkMode) {
      CookieService.setCookie('darkMode', 'true', 365);
    } else {
      CookieService.deleteCookie('darkMode');
    }
  }
}

export default DarkModeService
