import React from 'react'
import Layout from '../../components/public/Layout'

class Loading extends React.Component {
  render (): JSX.Element {
    const title = ''

    return (
        <Layout
            title={title}
            forgotPassword={false}
            register={false}
            login={false}
            forgotPasswordLogin={false}
            loading={true}
            children={<></>}

        />
    )
  }
}

export default Loading
