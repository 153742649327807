import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { onDisableCreateButton, onHideCloseButton, onModalShow } from '../../actions'
import CustomerFormData from '../../Models/CustomerFormData'

interface PropTypes {
  sendData: (data: CustomerFormData) => void
  onDisableCreateButton: (isDisabled: boolean) => void
  isDisabled?: boolean
}

interface StateTypes {
  companyName?: string
  kvkNumber?: string
  street?: string
  streetNumber?: string
  streetNumberAddition?: string | null
  postalCode?: string
  city?: string
  countryCode?: string

  phoneNumber?: string
  administrationFirstName?: string
  administrationInsertion?: string | null
  administrationLastName?: string
  email?: string
}

class CustomerForm extends React.Component<PropTypes, StateTypes> {
  constructor (props: PropTypes) {
    super(props)

    this.state = {
      companyName: undefined,
      kvkNumber: undefined,
      street: undefined,
      streetNumber: undefined,
      streetNumberAddition: null,
      postalCode: undefined,
      city: undefined,
      countryCode: undefined,

      phoneNumber: undefined,
      administrationFirstName: undefined,
      administrationInsertion: null,
      administrationLastName: undefined
    }

    this.handleChange = this.handleChange.bind(this)
    this.disableCreateButton = this.disableCreateButton.bind(this)
    this.formIsFilled = this.formIsFilled.bind(this)
  }

  componentDidMount (): void {
    this.disableCreateButton(true)
  }

  disableCreateButton (disable: boolean): void {
    this.props.onDisableCreateButton(disable)
  }

  handleChange (e: ChangeEvent<HTMLInputElement>): void {
    const name = e.currentTarget.name

    this.setState({ [name]: e.currentTarget.value }, () => {
      const validate = this.formIsFilled();
      this.disableCreateButton(!validate)
    });
  }

  formIsFilled (): boolean {
    for (const property in this.state) {
      // @ts-expect-error
      if (this.state[property] === undefined || this.state[property] === '') {
        return false
      }
    }

    this.props.sendData(this.state as CustomerFormData)
    return true
  }

  render (): JSX.Element {
    return (
        <div className="card">
          <div className="body">

            <h3> Bedrijf: </h3>
            <div className="input-group mb-3">
              <input
                  name="companyName"
                  aria-describedby="basic-addon1"
                  aria-label="CompanyName"
                  className="form-control"
                  placeholder="Company Name"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.companyName}
                  required
              />
            </div>
            <div className="input-group mb-3">
              <input
                  name="kvkNumber"
                  aria-describedby="basic-addon1"
                  aria-label="KvkNumber"
                  className="form-control"
                  placeholder="Kvk-number"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.kvkNumber}
                  required
              />
            </div>
            <br/>
            <h3>Adres: </h3>
            <div className="input-group mb-3">
              <input
                  name="street"
                  aria-describedby="basic-addon1"
                  aria-label="Street"
                  className="form-control"
                  placeholder="Street"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.street}
                  required
              />
            </div>
            <div className="input-group mb-3">
              <input
                  name="streetNumber"
                  aria-describedby="basic-addon1"
                  aria-label="StreetNumber"
                  className="form-control"
                  placeholder="Street Number"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.streetNumber}
                  required
              />
            </div>
            <div className="input-group mb-3">
              <input
                  name="streetNumberAddition"
                  aria-describedby="basic-addon1"
                  aria-label="StreetNumberAddition"
                  className="form-control"
                  placeholder="Street Number Addition"
                  onChange={this.handleChange}
                  value={this.state.streetNumberAddition ?? undefined}
                  type="text"
              />
              <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                        * optional
                    </span>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                  name="city"
                  aria-describedby="basic-addon1"
                  aria-label="City"
                  className="form-control"
                  placeholder="City"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.city}
                  required
              />
            </div>
            <div className="input-group mb-3">
              <input
                  name="postalCode"
                  aria-describedby="basic-addon1"
                  aria-label="PostalCode"
                  className="form-control"
                  placeholder="Zip-Code"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.postalCode}
                  required
              />
            </div>
            <div className="input-group mb-3">
              <input
                  name="countryCode"
                  aria-describedby="basic-addon1"
                  aria-label="CountryCode"
                  className="form-control"
                  placeholder="Country Code"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.countryCode}
                  required
              />
            </div>
            <br/>

            <h3>Contact persoon: </h3>
            <div className="input-group mb-3">
              <input
                  name="administrationFirstName"
                  aria-describedby="basic-addon1"
                  aria-label="AdministrationFirstName"
                  className="form-control"
                  placeholder="Voornaam"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.administrationFirstName}
                  required
              />
            </div>
            <div className="input-group mb-3">
              <input
                  name="administrationInsertion"
                  aria-describedby="basic-addon1"
                  aria-label="AdministrationInsertion"
                  className="form-control"
                  placeholder="tussenvoegsel"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.administrationInsertion ?? undefined}
              />
            </div>
            <div className="input-group mb-3">
              <input
                  name="administrationLastName"
                  aria-describedby="basic-addon1"
                  aria-label="AdministrationLastName"
                  className="form-control"
                  placeholder="Achternaam"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.administrationLastName}
                  required
              />
            </div>
            <div className="input-group mb-3">
              <input
                  name="phoneNumber"
                  aria-describedby="basic-addon1"
                  aria-label="PhoneNumber"
                  className="form-control"
                  placeholder="Telefoon number"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.phoneNumber}
                  required
              />
            </div>
            <div className="input-group mb-3">
              <input
                  name="email"
                  aria-describedby="basic-addon1"
                  aria-label="email"
                  className="form-control"
                  placeholder="email"
                  type="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  required
              />
            </div>
          </div>
        </div>
    )
  }
}

// @ts-expect-error
const mapStateToProps = ({modalReducer}): { show: boolean, isHidden: boolean, isDisabled: boolean } => ({
  show: modalReducer.show,
  isHidden: modalReducer.isHidden,
  isDisabled: modalReducer.isDisabled
})

export default connect(mapStateToProps, ({
  onModalShow,
  onHideCloseButton,
  onDisableCreateButton
}))(CustomerForm)
