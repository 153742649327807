import React from 'react'

interface LoadingProps {
  loaded: boolean
}

export default function Loading ({ loaded }: LoadingProps): JSX.Element {
  return (
      <div className="page-loader-wrapper" style={{ display: loaded ? 'block' : 'none' }}>
        <div className="loader">
          <div className="m-t-30">
            <h1 style={{ height: '40px', margin: '10px', color: '#FFF' }}>BizHost</h1>
          </div>
          <p>Please wait...</p>
        </div>
      </div>
  )
}
