import React from 'react'
import DnsRecordData from '../../Models/Dns/DnsRecordData'

interface PropTypes {
  dnsRecords?: DnsRecordData[]
}

class DnsRecordOverview extends React.Component<PropTypes, any> {
  render (): JSX.Element {
    return (
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead className="thead-dark">
                    <tr>
                        <th>#</th>
                        <th>Type</th>
                        <th className="hidden-sm-down">
                            Name
                        </th>
                        <th className="hidden-sm-down">
                            Value
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.dnsRecords?.map((dns: DnsRecordData, index: number) => {
                          return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{dns.type}</td>
                                    <td>{dns.name}</td>
                                    <td>{dns.value}</td>
                                </tr>
                          )
                        })
                    }
                    </tbody>
                </table>
            </div>
    )
  }
}

export default DnsRecordOverview
