import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';

import Page403 from '../pages/Shared/Page403';
import Page404 from '../pages/Shared/Page404';
import Page500 from '../pages/Shared/Page500';
import Page503 from '../pages/Shared/Page503';
import Maintenance from '../pages/Shared/Maintenance';

import Layout from '../components/secured/Layout';
import Analytical from '../pages/Dashboard/Analytical';
import ActiveSubscriptions from '../pages/Products/ActiveSubscriptions';
import LastInvoices from '../pages/Invoices/LastInvoices';
import ProductsOverview from '../pages/Products/ProductsOverview';
import VpsOverview from '../pages/Vps/VpsOverview';
import VpsDetails from '../pages/Vps/VpsDetails';
import InvoiceDetails from '../pages/Invoices/InvoiceDetails';
import EditAccount from '../pages/Account/EditAccount';
import CheckDomain from '../pages/Domain/CheckDomain';
import QuoteOverview from '../pages/Quotes/QuoteOverview';
import QuoteDetails from '../pages/Quotes/QuoteDetails';
import DomainOverview from '../pages/Domain/DomainOverview';
import DomainDetails from '../pages/Domain/DomainDetails';
import Logout from '../pages/Auth/Logout';
import PageNotFound from '../pages/Screens/PageNotFound';
import WordpressOverview from '../pages/Vps/WordpressOverview';
import WordpressDetails from '../pages/Vps/WordpressDetails';
import LinkedHelperVpsOverview from '../pages/Vps/LinkedHelperVpsOverview';
import LinkedHelperVpsDetails from '../pages/Vps/LinkedHelperVpsDetails';
import VpsDashboard from '../pages/Vps/VpsDashboard';

export default function Authorized(): JSX.Element {
  return (
      <Routes>

        <Route path="page403" element={
          <Layout>
            <Page403/>
          </Layout>
        }/>
        <Route path="page404" element={
          <Layout>
            <Page404/>
          </Layout>
        }/>
        <Route path="page500" element={
          <Layout>
            <Page500/>
          </Layout>
        }/>
        <Route path="page503" element={
          <Layout>
            <Page503/>
          </Layout>
        }/>
        <Route path="maintenance" element={
          <Layout>
            <Maintenance/>
          </Layout>
        }/>

        <Route path="logout" element={
          <Logout></Logout>
        }/>

        <Route path="dashboard/analytical" element={
          <Layout>
            <Analytical/>
          </Layout>
        }/>
        <Route path="dashboard" element={
          <Layout>
            <Dashboard/>
          </Layout>
        }/>
        <Route path="products/active" element={
          <Layout>
            <ActiveSubscriptions/>
          </Layout>
        }/>
        <Route path="products/overview" element={
          <Layout>
            <ProductsOverview/>
          </Layout>
        }/>

        <Route path="finance/invoices/recent" element={
          <Layout>
            <LastInvoices/>
          </Layout>
        }/>
        <Route path="finance/invoices/details" element={
          <Layout>
            <InvoiceDetails/>
          </Layout>
        }/>
        <Route path="finance/invoices" element={
          <Navigate to="/finance/invoices/recent" replace/>
        }/>
        <Route path="finance/quotes/recent" element={
          <Layout>
            <QuoteOverview/>
          </Layout>
        }/>
        <Route path="finance/quotes/details" element={
          <Layout>
            <QuoteDetails/>
          </Layout>
        }/>
        <Route path="finance/quotes" element={
          <Layout>
            <Navigate to="/finance/quotes/recent" replace/>
          </Layout>
        }/>

        <Route path="vps/wordpress/overview" element={
          <Layout>
            <WordpressOverview/>
          </Layout>
        }/>
        <Route path="vps/wordpress/:serverId" element={
          <Layout>
            <WordpressDetails/>
          </Layout>
        }/>

        <Route path="vps/generic/overview" element={
          <Layout>
            <VpsOverview/>
          </Layout>
        }/>
        <Route path="vps/generic/:serverId" element={
          <Layout>
            <VpsDetails/>
          </Layout>
        }/>

        <Route path="vps/linked-helper/overview" element={
          <Layout>
            <LinkedHelperVpsOverview/>
          </Layout>
        }/>
        <Route path="vps/linked-helper/:serverId" element={
          <Layout>
            <LinkedHelperVpsDetails/>
          </Layout>
        }/>

        <Route path="vps" element={
          <Layout>
            <VpsDashboard/>
          </Layout>
        }/>

        <Route path="account/change" element={
          <Layout>
            <EditAccount/>
          </Layout>
        }/>

        <Route path="domain/check" element={
          <Layout>
            <CheckDomain/>
          </Layout>
        }/>
        <Route path="domain/dashboard" element={
          <Layout>
            <DomainOverview/>
          </Layout>
        }/>
        <Route path="domain/details" element={
          <Layout>
            <DomainDetails/>
          </Layout>
        }/>
        <Route path="not-found" element={
          <Layout>
            <PageNotFound/>
          </Layout>
        }/>

        <Route path="*" element={
          <Navigate to="/not-found" replace/>
        }/>
      </Routes>
  );
}
