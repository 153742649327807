import ApiService, { ApiResponse } from './ApiService';
import UserData from '../Models/UserData';
import RegisterUserData from '../Models/RegisterUserData';
import CustomerData from '../Models/CustomerData';
import { Base64 } from 'js-base64';

class AccountService {
  static localStorageUserKey = 'currentUser';
  private readonly api: ApiService;

  constructor() {
    this.api = new ApiService();
  }

  static hasUserInfo(): boolean {
    try {
      const currentUser = AccountService.getCurrentUserFromLocalStorage();

      return (currentUser.uuid !== null && currentUser.email !== null);
    } catch (e) {
        return false;
    }
  }

  static getCurrentUserFromLocalStorage(): UserData {
    // Try to set it from local storage
    const rawCurrentUser = localStorage.getItem(AccountService.localStorageUserKey);

    if (rawCurrentUser === null) {
      throw new Error('Could not parse the currentUser from localStorage');
    }

    return JSON.parse(Base64.decode(rawCurrentUser));
  }

  getCurrentUserFromApi(): Promise<UserData> {
    return new Promise((resolve, reject) => {
      this.api.apiCall('/api/v1/account')
          .then((response: ApiResponse) => {
            this.processUserResponse(response).then(resolve).catch(reject);
          })
          .catch((error) => {
            console.log({error});
            reject(error);
          });
    });
  }

  processUserResponse(response: ApiResponse): Promise<UserData> {
    return new Promise((resolve, reject) => {

      try {
        const responseCurrentUser = AccountService.transformResponseDataToUserData(response);
        if (responseCurrentUser.uuid === null || responseCurrentUser.email === null) {
          return reject(new Error('Could not parse the currentUser from the API response'));
        }

        localStorage.setItem(AccountService.localStorageUserKey, Base64.encode(JSON.stringify(responseCurrentUser)));
        return resolve(responseCurrentUser);
      } catch (e) {
        console.log({e});
        return reject(e);
      }
    });
  }

  getCustomersFromUser():CustomerData[] | undefined {
      const user = AccountService.getCurrentUserFromLocalStorage();
      if (user.customers != null) {
        return user.customers;
      }

      return undefined;
  }

  registerUser(userData: RegisterUserData): Promise<boolean> {
    return new Promise((resolve, reject) => {
      ApiService.nonAuthApiCall('/api/v1/account/create', 'POST', JSON.stringify(userData)).then((response: ApiResponse) => {
        if (response.status !== 200) {
          throw new Error(response.meta?.error?.message);
        }
        resolve(true);
      }).catch(reject);
    });
  }

  updateUser(userData: UserData): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.apiCall(`/api/v1/account/${userData.uuid}`, 'PUT', JSON.stringify(userData)).then((response: ApiResponse) => {
        if (response.status !== 200) {
          throw new Error(response.meta?.error?.message);
        }
        return resolve(true);
      }).catch(reject);
    });
  }

  async updatePassword(uuid: string, newPassword: string): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      this.api.apiCall(`/api/v1/account/password/${uuid}`, 'PUT', JSON.stringify({password: newPassword})).then((response: ApiResponse) => {
        if (response.status !== 200) {
          throw new Error(response.meta?.error?.message);
        }
        return resolve(true);
      }).catch(reject);
    });
  }

  async updateUsername(uuid: string, username: string): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      this.api.apiCall(`/api/v1/account/username/${uuid}`, 'PUT', JSON.stringify({username})).then((response: ApiResponse) => {
        if (response.status !== 200) {
          throw new Error(response.meta?.error?.message);
        }
        return resolve(true);
      }).catch(reject);
    });
  }

  async verifyUserEmail(emailVerifyToken: string | null): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      if (emailVerifyToken !== null) {
        ApiService.nonAuthApiCall(`/api/v1/verify/email/${emailVerifyToken}`, 'PUT').then((response: ApiResponse) => {
          if (response.status !== 200) {
            throw new Error(response.meta?.error?.message);
          }
          return resolve(true);
        }).catch(reject);
      } else {
        return resolve(false);
      }
    });
  }

  private static transformResponseDataToUserData(response: ApiResponse): UserData {
    const rawResponseUserData = response.data;

    return {
      uuid: String(rawResponseUserData.uuid),
      username: String(rawResponseUserData.username),
      firstName: String(rawResponseUserData.firstName),
      insertion: (rawResponseUserData.insertion !== '' ? String(rawResponseUserData.insertion) : null),
      lastName: String(rawResponseUserData.lastName),
      email: String(rawResponseUserData.email),
      roles: rawResponseUserData.roles,
      apiToken: String(rawResponseUserData.apiToken),
      customers: rawResponseUserData.customers,
      active: Boolean(rawResponseUserData.active === 1),
      deletedOn: rawResponseUserData.deletedOn !== null ? new Date(rawResponseUserData.deletedOn * 1000) : null,
      createdOn: new Date(rawResponseUserData.createdOn * 1000),
      updatedOn: rawResponseUserData.updatedOn !== null ? new Date(rawResponseUserData.updatedOn * 1000) : null
    };
  }
}

export default AccountService;
