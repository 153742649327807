import ApiService, { ApiResponse } from './ApiService'
import VpsData from '../Models/Vps/VpsData'

class VpsService {
  private readonly api: ApiService

  constructor () {
    this.api = new ApiService()

    this.createVpsServer = this.createVpsServer.bind(this)
    this.getAllVpsServers = this.getAllVpsServers.bind(this)
    this.deleteVpsServer = this.deleteVpsServer.bind(this)
  }

  createVpsServer (
    hostName: string,
    labels: object,
    serverType: 'vps' | 'wordpress' | 'linked-helper',
  ): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      this.api.apiCall(
        `/api/v1/${serverType}/hosting/servers`,
        'POST',
        JSON.stringify({
          hostname: hostName,
          labels,
          subscription: "8d32008e-a655-44ab-8d53-fffce452878d"
        })
      ).then(response => {
        return resolve(response)
      }).catch(reject)
    })
  }

  getAllLinkedHelperVpsServers (): Promise<VpsData[]> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall('/api/v1/linked-helper/hosting/servers')
        .then((response: ApiResponse) => {
          return resolve(response.data)
        })
        .catch(reject)
    })
  }
  getAllWordpressVpsServers (): Promise<VpsData[]> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall('/api/v1/wordpress/hosting/servers')
        .then((response: ApiResponse) => {
          return resolve(response.data)
        })
        .catch(reject)
    })
  }
  getAllVpsServers (): Promise<VpsData[]> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall('/api/v1/vps/hosting/servers')
        .then((response: ApiResponse) => {
          return resolve(response.data)
        })
        .catch(reject)
    })
  }

  updateVpsServer (
    serverId: number,
    requestData: { hostName: string, labels: object }
  ): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall(
          `/api/v1/vps/hosting/servers/${serverId}`,
          'PUT',
          JSON.stringify({
            name: requestData.hostName,
            labels: { ...requestData.labels, Client: 'Schurq' }
          })
        )
        .then((response: ApiResponse) => {
          return resolve(response)
        })
        .catch(reject)
    })
  }

  deleteVpsServer (serverId: number): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall(`/api/v1/vps/hosting/servers/${serverId}`, 'DELETE')
        .then((response: ApiResponse) => {
          return resolve(response)
        })
        .catch(reject)
    })
  }

  startVpsServer (serverId: number): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall(
          `/api/v1/vps/hosting/servers/${serverId}/start`,
          'PUT'
        )
        .then((response: ApiResponse) => {
          return resolve(response)
        })
        .catch(reject)
    })
  }

  restartVpsServer (serverId: number): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall(
          `/api/v1/vps/hosting/servers/${serverId}/restart`,
          'PUT'
        )
        .then((response: ApiResponse) => {
          return resolve(response)
        })
        .catch(reject)
    })
  }

  stopVpsServer (serverId: number): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall(
          `/api/v1/vps/hosting/servers/${serverId}/stop`,
          'PUT'
        )
        .then((response: ApiResponse) => {
          return resolve(response)
        })
        .catch(reject)
    })
  }

  findVpsServer (serverId: number): Promise<VpsData> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall(`/api/v1/vps/hosting/servers/${serverId}`)
        .then((response: ApiResponse) => {
          return resolve(response.data)
        })
        .catch(reject)
    })
  }

  getCpuData (serverId: number, period: string): Promise<any[][]> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall(
          `/api/v1/vps/hosting/servers/${serverId}/metrics?resourceType=cpu&period=${period}`
        )
        .then((response: ApiResponse) => {
          return resolve(response.data)
        })
        .catch(reject)
    })
  }

  getDiskData (serverId: number, period: string): Promise<any[][]> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall(
          `/api/v1/vps/hosting/servers/${serverId}/metrics?resourceType=disk&period=${period}`
        )
        .then((response: ApiResponse) => {
          return resolve(response.data)
        })
        .catch(reject)
    })
  }

  getNetworkData (serverId: number, period: string): Promise<any[][]> {
    return new Promise((resolve, reject) => {
      this.api
        .apiCall(
          `/api/v1/vps/hosting/servers/${serverId}/metrics?resourceType=network&period=${period}`
        )
        .then((response: ApiResponse) => {
          return resolve(response.data)
        })
        .catch(reject)
    })
  }

  generateXmlClientConnectionFile (ip: string): string {
    const template =
      '<!DOCTYPE NXClientSettings>\n' +
      '<NXClientSettings version="2.1" application="nxclient" >\n' +
      ' <group name="Advanced" >\n' +
      '  <option key="Enable remote cursor tracking" value="" />\n' +
      '  <option key="Disable updating clipboard on mouse selection" value="false" />\n' +
      '  <option key="Grab keyboard input" value="" />\n' +
      '  <option key="Grab mouse input" value="" />\n' +
      '  <option key="Enable HTTP proxy" value="false" />\n' +
      '  <option key="HTTP proxy host" value="" />\n' +
      '  <option key="HTTP proxy port" value="8080" />\n' +
      '  <option key="HTTP proxy password" value="EMPTY_PASSWORD" />\n' +
      '  <option key="Remember HTTP proxy password" value="false" />\n' +
      '  <option key="HTTP proxy username" value="" />\n' +
      '  <option key="Emulate middle mouse button" value="" />\n' +
      '  <option key="Use TOR for SOCKS proxy connections" value="false" />\n' +
      '  <option key="Use URL for automatic proxy configuration" value="false" />\n' +
      '  <option key="Manual proxy configuration type" value="http" />\n' +
      '  <option key="Proxy configuration mode" value="automatic" />\n' +
      '  <option key="Automatic proxy configuration URL" value="" />\n' +
      ' </group>\n' +
      ' <group name="Environment" >\n' +
      '  <option key="Use font server" value="false" />\n' +
      '  <option key="Font server host" value="" />\n' +
      '  <option key="Font server port" value="7100" />\n' +
      ' </group>\n' +
      ' <group name="General" >\n' +
      '  <option key="Connection request greeting message" value="" />\n' +
      '  <option key="Automatically connect to session with specified ID" value="" />\n' +
      '  <option key="Enable session auto-resize" value="" />\n' +
      '  <option key="Physical desktop auto-resize" value="true" />\n' +
      '  <option key="Virtual desktop auto-resize" value="false" />\n' +
      '  <option key="Close the client application when a single session terminates" value="" />\n' +
      '  <option key="Connection service" value="nx" />\n' +
      '  <option key="Always create a physical desktop on a headless Linux host" value="false" />\n' +
      '  <option key="View a specific monitor among available monitors" value="0" />\n' +
      '  <option key="Command line" value="" />\n' +
      '  <option key="Use custom server" value="false" />\n' +
      '  <option key="Custom server command" value="/etc/NX/nxserver" />\n' +
      '  <option key="Custom Unix Desktop" value="" />\n' +
      '  <option key="NoMachine daemon port" value="4000" />\n' +
      '  <option key="Connect to this node when manual selection is enabled" value="" />\n' +
      '  <option key="Connect to this server when manual selection is enabled" value="" />\n' +
      '  <option key="Desktop" value="" />\n' +
      '  <option key="Disable SHM" value="false" />\n' +
      '  <option key="Disable emulate shared pixmaps" value="false" />\n' +
      '  <option key="Use render" value="true" />\n' +
      '  <option key="Try to wake up server when it is powered off" value="false" />\n' +
      '  <option key="Last connection time" value="1671324411" />\n' +
      '  <option key="Link quality" value="5" />\n' +
      '  <option key="Platform of the last connection" value="Linux" />\n' +
      '  <option key="Node primary display resolution" value="1653x1027" />\n' +
      '  <option key="Node UUID" value="c25d3655-c255-48ab-8d28-8ccd1a601b23" />\n' +
      '  <option key="Recently connected nodes" value="" />\n' +
      '  <option key="Physical desktop resize mode" value="scaled" />\n' +
      '  <option key="Session resize mode" value="" />\n' +
      '  <option key="Virtual desktop resize mode" value="viewport" />\n' +
      '  <option key="Server MAC address" value="" />\n' +
      '  <option key="Resize the remote screen upon connecting" value="no" />\n' +
      '  <option key="Resolution" value="" />\n' +
      '  <option key="Use WebRTC for multimedia data" value="true" />\n' +
      '  <option key="Save connection request greeting message" value="false" />\n' +
      '  <option key="Remember session window size and position" value="" />\n' +
      '  <option key="Remember password" value="true" />\n' +
      '  <option key="Remember username" value="true" />\n' +
      '  <option key="Session screenshot" value="" />\n' +
      '  <option key="Server hardware" value="Hetzner vServer" />\n' +
      '  <option key="Server host" value="<REPLACE_IP>" />\n' +
      '  <option key="Server port" value="22" />\n' +
      '  <option key="Server product" value="NoMachine" />\n' +
      '  <option key="Server product version" value="8.2.3" />\n' +
      '  <option key="Server hardware specifications" value="Intel Xeon Processor (Skylake, IBRS):4013912064" />\n' +
      '  <option key="Session" value="" />\n' +
      '  <option key="Show connection request greeting message panel" value="true" />\n' +
      '  <option key="Show expiring license warning message" value="true" />\n' +
      '  <option key="Show remote audio alert message" value="false" />\n' +
      '  <option key="Show remote display resize message" value="true" />\n' +
      '  <option key="Show remote desktop view mode message" value="false" />\n' +
      '  <option key="UDP communication port" value="" />\n' +
      '  <option key="Use UDP communication for multimedia data" value="true" />\n' +
      '  <option key="Use specific port for UDP communication" value="false" />\n' +
      '  <option key="Virtual desktop" value="false" />\n' +
      '  <option key="NoMachine web server port" value="4443" />\n' +
      '  <option key="Web session information token" value="" />\n' +
      '  <option key="Session window geometry" value="0, 65, 1653, 1027" />\n' +
      '  <option key="Session window state" value="normal" />\n' +
      '  <option key="xdm mode" value="" />\n' +
      '  <option key="xdm broadcast port" value="177" />\n' +
      '  <option key="xdm list host" value="localhost" />\n' +
      '  <option key="xdm list port" value="177" />\n' +
      '  <option key="xdm query host" value="localhost" />\n' +
      '  <option key="xdm query port" value="177" />\n' +
      '  <option key="Use custom resolution" value="false" />\n' +
      '  <option key="Resolution width" value="" />\n' +
      '  <option key="Resolution height" value="" />\n' +
      ' </group>\n' +
      ' <group name="Login" >\n' +
      '  <option key="Always use selected user login" value="false" />\n' +
      '  <option key="Alternate NX Key" value="" />\n' +
      '  <option key="Forward SSH authentication" value="false" />\n' +
      '  <option key="Guest Mode" value="false" />\n' +
      '  <option key="Guest password" value="" />\n' +
      '  <option key="Guest username" value="" />\n' +
      '  <option key="Guest server" value="" />\n' +
      '  <option key="Guest desktop sharing mode" value="false" />\n' +
      '  <option key="Last selected user login" value="system user" />\n' +
      '  <option key="Server authentication method" value="system" />\n' +
      '  <option key="Auth" value="]KLXfms!!+4FLNUipx%$7ADNYbbk|%(2DJKSgjmv-+9GFNVjpqy107ARW]mtx*23;MSXhnu$+2@DJXVcj{z+:AIS_" />\n' +
      '  <option key="User" value="schurq" />\n' +
      '  <option key="Wallix login" value="" />\n' +
      '  <option key="NX login method" value="password" />\n' +
      '  <option key="Private key for NX authentication" value="" />\n' +
      '  <option key="Use alternate smart card module" value="false" />\n' +
      '  <option key="Smart card authentication module" value="" />\n' +
      '  <option key="Private key" value="" />\n' +
      '  <option key="Public Key" value="" />\n' +
      '  <option key="System auth" value="EMPTY_PASSWORD" />\n' +
      '  <option key="Two-factor authentication password" value="EMPTY_PASSWORD" />\n' +
      '  <option key="Remember NoMachine password" value="false" />\n' +
      '  <option key="Remember two-factor authentication password" value="false" />\n' +
      '  <option key="Imported private key for NX authentication" value="" />\n' +
      '  <option key="Use imported private key for NX authentication" value="false" />\n' +
      '  <option key="Imported private key" value="" />\n' +
      '  <option key="Use imported private key" value="false" />\n' +
      '  <option key="System login method" value="password" />\n' +
      '  <option key="Use alternate NX Key" value="false" />\n' +
      '  <option key="GSSAPI subsystem for the kerberos authentication" value="kerberos" />\n' +
      '  <option key="Kerberos key exchange to identify server host" value="false" />\n' +
      '  <option key="DNS translation when passing server to Kerberos library" value="false" />\n' +
      ' </group>\n' +
      ' <group name="Images" >\n' +
      '  <option key="Disable network-adaptive quality" value="false" />\n' +
      '  <option key="Disable backingstore" value="false" />\n' +
      '  <option key="Disable composite" value="false" />\n' +
      '  <option key="Disable image post-processing" value="false" />\n' +
      '  <option key="Disable frame buffering on decoding" value="false" />\n' +
      '  <option key="Disable lossless display refinement" value="false" />\n' +
      '  <option key="Disable multi-pass display encoding" value="false" />\n' +
      '  <option key="E-reader display update policy" value="false" />\n' +
      '  <option key="Video frame rate for display server" value="30" />\n' +
      '  <option key="Stream downsampling factor" value="0" />\n' +
      '  <option key="Request a specific frame rate" value="false" />\n' +
      '  <option key="Video encoding quality" value="5" />\n' +
      ' </group>\n' +
      ' <group name="Services" >\n' +
      '  <option key="Output audio device" value="autodetect" />\n' +
      '  <option key="Output audio quality" value="5" />\n' +
      '  <option key="Audio" value="true" />\n' +
      '  <option key="IPPPrinting" value="true" />\n' +
      '  <option key="Enable devices sharing" value="true" />\n' +
      '  <option key="Shares" value="true" />\n' +
      '  <option key="Mute audio of the remote physical desktop" value="true" />\n' +
      '  <option key="Input voice device" value="autodetect" />\n' +
      '  <option key="Input voice quality" value="5" />\n' +
      ' </group>\n' +
      ' <group name="VNC Session" >\n' +
      '  <option key="Display" value=":0" />\n' +
      '  <option key="Password" value="EMPTY_PASSWORD" />\n' +
      '  <option key="Remember login credentials" value="false" />\n' +
      '  <option key="Remember" value="false" />\n' +
      '  <option key="Server" value="" />\n' +
      ' </group>\n' +
      ' <group name="Windows Session" >\n' +
      '  <option key="Application" value="" />\n' +
      '  <option key="Authentication" value="2" />\n' +
      '  <option key="Domain" value="" />\n' +
      '  <option key="Password" value="EMPTY_PASSWORD" />\n' +
      '  <option key="Remember login credentials" value="false" />\n' +
      '  <option key="Remember" value="false" />\n' +
      '  <option key="Run application" value="false" />\n' +
      '  <option key="Server" value="" />\n' +
      '  <option key="User" value="" />\n' +
      ' </group>\n' +
      ' <group name="Remote Session" >\n' +
      '  <option key="Host" value="" />\n' +
      '  <option key="Port" value="" />\n' +
      '  <option key="Username" value="" />\n' +
      '  <option key="Password" value="EMPTY_PASSWORD" />\n' +
      '  <option key="Remember login credentials" value="false" />\n' +
      '  <option key="Fingerprint" value="" />\n' +
      ' </group>\n' +
      '</NXClientSettings>\n'

    return template.replace('<REPLACE_IP>', ip)
  }
}

export default VpsService
