import React from 'react'
import DomainService from '../../Services/DomainService'
import DomainData from '../../Models/DomainData'
import ListTileSwitch from '../../components/Overview/ListTileSwitch'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import { setDomainDetails } from '../../actions'

interface PropTypes {
  domainDetails: DomainData | null
  setDomainDetails: (val: DomainData | null) => {}
}
interface StateTypes {
  domains: DomainData[]
  listFormat: boolean
}
class DomainOverview extends React.Component<PropTypes, StateTypes> {
  private readonly domainService: DomainService

  constructor (props: PropTypes) {
    super(props)

    this.domainService = new DomainService()

    this.state = {
      domains: [],
      listFormat: false
    }

    this.saveDomainDetails = this.saveDomainDetails.bind(this)
  }

  componentDidMount (): void {
    this.domainService.getDomainsFromCustomer().then(domains => {
      this.setState({ domains })
    })
  }

  saveDomainDetails (domainDetails: DomainData): void {
    localStorage.setItem('currentDomain', domainDetails.uuid as string)
    this.props.setDomainDetails(domainDetails)
  }

  renderList (): JSX.Element {
    return (
        <div className="body table-responsive">
          <table className="table">
            <thead>
            <tr>
              <th>NAME</th>
              <th>...</th>
            </tr>
            </thead>
            <tbody>
            {this.state.domains?.map((domain, i) => {
              return (
                  <tr key={i}>
                    <td>{domain.domainName}.{domain.tld}</td>
                    <td><Link to='/domain/details'><button onClick={() => { this.saveDomainDetails(domain) }} className='btn btn-primary'>More details</button></Link></td>
                  </tr>
              )
            })}
            </tbody>
          </table>
        </div>
    )
  }

  renderTiles (): JSX.Element {
    return (
        <>
          <div className="row" >
            { this.state.domains?.map((domain, index) => {
              return (
                  <div className="col-xl-4 col-lg-6 col-md-12" key={index}>
                    <Card>
                      <Card.Body>
                        <Card.Title>{domain.domainName}.{domain.tld}</Card.Title>
                        <Card.Text>
                          <Link to='/domain/details'>
                              <button onClick={() => { this.saveDomainDetails(domain) }} className='btn btn-primary'>
                                  More details
                              </button>
                          </Link>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
              )
            })}
          </div>
        </>
    )
  }

  render (): JSX.Element {
    return (
        <div className="col-lg-12">
          <div className="card">
            <div className="header d-flex justify-content-between">
              <h2>
                My Domains
              </h2>
              <ListTileSwitch setList={() => { this.setState({ listFormat: true }) }} setTile={() => { this.setState({ listFormat: false }) }} />
            </div>
            {
              this.state.listFormat
                ? this.renderList()
                : null
            }
          </div>
          {
            !this.state.listFormat
              ? this.renderTiles()
              : null
          }
        </div>
    )
  }
}

// @ts-expect-error
const mapStateToProps = ({ domainReducer }): { domainDetails: any } => ({
  domainDetails: domainReducer.domainDetails
})

export default connect(mapStateToProps, {
  setDomainDetails
// @ts-expect-error
})(DomainOverview)
