import React, { ReactElement } from 'react'
import { Button, Modal } from 'react-bootstrap'

interface PropTypes {
  title: string
  closeButtonText: string
  primaryButtonText: string
  bodyText?: string
  bodyElement?: ReactElement
  onClose?: () => void
  onSave?: (e: React.MouseEvent<HTMLButtonElement>) => any
  onModalShow?: (show: boolean) => void
  onHideCloseButton?: (isHidden: boolean) => void
  onDisableCreateButton?: (isDisabled: boolean) => void
  size?: 'sm' | 'lg' | 'xl'
  show?: boolean
  isHidden?: boolean
  isDisabled?: boolean
}

interface StateTypes {
  onClose: () => void
  onSave: (e: React.MouseEvent<HTMLButtonElement>) => void
}

class UIBaseModal extends React.Component<PropTypes, StateTypes> {
  constructor (props: PropTypes = {
    title: '',
    closeButtonText: '',
    primaryButtonText: '',
    size: 'lg'
  }) {
    super(props)
  }

  render (): JSX.Element {
    const { title, bodyText, bodyElement, closeButtonText, primaryButtonText, size, show, isHidden, isDisabled, onClose, onSave } = this.props

    return (
            <Modal size={size} show={show} onHide={onClose}>
                {
                    isHidden
                      ? <Modal.Header>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                      : <Modal.Header closeButton>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                }

                <Modal.Body>
                    {
                        bodyText !== ''
                          ? <>
                                { bodyText }
                            </>
                          : null
                    }

                    { bodyElement }
                </Modal.Body>

                <Modal.Footer>
                    {
                        !isHidden
                          ? <Button variant="secondary" onClick={onClose}>
                            { closeButtonText }
                        </Button>
                          : null
                    }
                    <Button variant="primary" onClick={onSave} disabled={isDisabled}>
                        { primaryButtonText }
                    </Button>
                </Modal.Footer>
            </Modal>
    )
  }
}

export default UIBaseModal
