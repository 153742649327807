import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import ResponseData from '../../Models/OpenProvider/ResponseData'
import DomainService from '../../Services/DomainService'
import DomainData from '../../Models/DomainData'

interface PropTypes {
  domain: ResponseData
  onSubmit: () => void
  onSuccessful: () => void
}

interface StateTypes {
  show: boolean
}
class DomainConfirmation extends React.Component<PropTypes, StateTypes> {
  private readonly domainService: DomainService
  constructor (props: PropTypes) {
    super(props)

    this.domainService = new DomainService()

    this.state = {
      show: false
    }

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  handleShow (): void {
    this.setState({ show: true })
  }

  handleClose (): void {
    this.setState({ show: false })
  }

  onSubmit (): void {
    DomainService.OpenProviderResToDomainData(this.props.domain).then((response: DomainData) => {
      this.props.onSubmit()
      this.domainService.registerDomain(response).then(response => {
        if (response.status === 200) {
          this.setState({ show: false })
          this.props.onSuccessful()
        }
      }).catch(console.log)
    }).catch(console.log)
  }

  render (): JSX.Element {
    return (
            <>
                <Button onClick={this.handleShow} disabled={this.props.domain.status !== 'free'} variant="dark">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-cart" viewBox="0 0 16 16">
                        <path
                            d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    &nbsp;&nbsp;
                    Buy domain
                    &nbsp;&nbsp;
                </Button>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to order <b>{this.props.domain.domain}</b>?</p>
                        <br />
                        <h4>{this.props.domain.price.product.currency} {(this.props.domain.price.product.price * 2).toFixed(2) }</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={this.onSubmit}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
    )
  }
}

export default DomainConfirmation
