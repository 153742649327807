import React, { useEffect } from 'react';
import Layout from '../../components/public/Layout';
import ToolsService from '../../Services/ToolsService';
import TokenService from '../../Services/TokenService';



const SignOut = () => {

    useEffect(() => {
    const redirect = () => {
      TokenService.removeAccessTokenCookie();
      localStorage.clear();
      ToolsService.navigateTo('/login');
    };

    setTimeout(redirect, 600);
  }, []);

  const title = "Logout user";

  return (
      <Layout
          title={title}
          forgotPassword={false}
          register={false}
          login={false}
          forgotPasswordLogin={false}
          loading={true}
          children={<></>}
      />
  );
};

export default SignOut;
