import ApiService, { ApiResponse } from './ApiService'
import CustomerService from './CustomerService'
import QuoteData from '../Models/QuoteData'
import CustomerData from '../Models/CustomerData'

class QuoteService {
  private readonly api: ApiService
  private readonly customerService: CustomerService

  constructor () {
    this.api = new ApiService()
    this.customerService = new CustomerService()
  }

  getQuotesFromCustomer (): Promise<QuoteData[]> {
    return new Promise((resolve, reject) => {
      this.customerService.getActiveCustomer().then((activeCustomer: CustomerData | null) => {
        if (activeCustomer == null) {
          return reject
        }

        const url = `/api/v1/${activeCustomer.uuid}/quote`

        this.api.apiCall(url).then((response: ApiResponse) => {
          return resolve(response.data)
        }).catch(reject)
      }).catch(reject)
    })
  }

  filterRecentQuote (quotes: QuoteData[]): Promise<QuoteData[]> {
    return new Promise((resolve, reject) => {
      const filteredQuotes: QuoteData[] = []
      const now: number = Date.now()
      const threeMonthsAgo: number = (now - (((60000 * 60) * (24)) * (30)) * 3) / 1000
      quotes.forEach((quote) => {
        if (quote.createdOn > threeMonthsAgo) {
          filteredQuotes.push(quote)
        }
      })

      return resolve(filteredQuotes)
    })
  }
}

export default QuoteService
