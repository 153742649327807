import TokenService from './TokenService'
import AuthService from './AuthService'
import CustomerService from './CustomerService'

export interface ApiResponse {
  data: any
  meta: {
    version: string
    api: string
    auth: string
    success: boolean
    info: string
    error: null | {
      message: string
      code: number
    }
    total: number
  }
  status: number
}

class ApiService {
  static apiUrl = process.env.REACT_APP_BHP_API_URL ?? 'http://not-configured'

  apiCall (url: string, method = 'GET', body: any = null): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      const accessToken = TokenService.getAccessTokenCookie()
      const customerUuid = CustomerService.getActiveCustomerUuid()

      if (accessToken === null) {
          return reject('Unauthorized')
      }

      this.call(url, method, body, accessToken, customerUuid)
        .then(resolve)
        .catch(reason => {
          if (reason === 'Unauthorized') {
            // @todo

            console.error('TODO: IMPLEMENT refreshToken flow')
          } else {
            return reject(reason)
          }
        })
    })
  }

  public static async nonAuthApiCall (url: string, method = 'GET', body: any = null): Promise<ApiResponse> {
    const api = new ApiService()
    return await api.call(url, method, body)
  }

  private call (url: string, method = 'GET', body: any = null, accessToken: string | null = null, customerUuid: string | null = null): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      const requestOptions: RequestInit = {
        method,
        mode: 'cors',
        headers: {}
      }

      if (url !== '/token') {
        // @ts-expect-error
        requestOptions.headers['Content-Type'] = 'application/json'
      }

      if (body !== null) {
        requestOptions.body = body
      }

      if (accessToken !== null) {
        // @ts-expect-error
        requestOptions.headers.Authorization = `Bearer ${accessToken}`
      }

      if (customerUuid !== null) {
        // @ts-expect-error
        requestOptions.headers['x-active-customer'] = customerUuid
      }

      fetch(`${ApiService.apiUrl}${url}`, requestOptions)
        .then(response => {
          if (response.status === 401) {
            throw new Error('Unauthorized')
          }
          const jsonResponse = response.json()

          if (jsonResponse === null) {
            throw new Error('Could not parse the apiCall Response')
          }

          resolve(jsonResponse)
        })
        .catch(reject)
    })
  }
}

export default ApiService
