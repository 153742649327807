

class CookieService {
  static setCookie (cookieName: string, cookieValue: string, expiresSeconds: number): void {
    const d = new Date()
    const microSeconds = 1000
    d.setTime(d.getTime() + (expiresSeconds * microSeconds))
    const expires = `expires=${d.toUTCString()}`
    const sec = window.location.protocol === 'https:' ? ';secure' : ''
    const domain = `;domain=${window.location.hostname}`
    document.cookie = `${cookieName}=${cookieValue};${expires};path=/;${sec}${domain};samesite=strict`
  }

  static getCookie (cookieName: string): string | null {
    const name = cookieName + '='
    const decodedCookie = decodeURIComponent(document.cookie)

    const dc = decodedCookie.split(';')
    for (let i = 0; i < dc.length; i++) {
      let cookie = dc[i]
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1)
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name?.length, cookie?.length)
      }
    }
    return null
  }

  static deleteCookie (cookieName: string): void {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
  }
}

export default CookieService
