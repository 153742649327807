import React from 'react'
import DnsZoneOverview from '../Dns/DnsZoneOverview'
import DatabaseOverview from '../Database/DatabaseOverview'

class DomainSettings extends React.Component<any, any> {
  render (): JSX.Element {
    return (
      <>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="dns-tab"
              data-bs-toggle="tab"
              href="#dns"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              DNS
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="database-tab"
              data-bs-toggle="tab"
              href="#database"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Databases
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="dns"
            role="tabpanel"
            aria-labelledby="dns-tab"
          >
            <DnsZoneOverview />
          </div>
          <div
            className="tab-pane fade"
            id="database"
            role="tabpanel"
            aria-labelledby="database-tab"
          >
            <DatabaseOverview />
          </div>
        </div>
      </>
    )
  }
}

export default DomainSettings
