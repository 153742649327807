export interface AuthConfig {
  clientId: string;
  endpoint: string;
  redirectUri: string;
  scope: string;
}

class AuthService {
  private readonly authConfig: AuthConfig;

  constructor() {
    this.authConfig = this.loadEnvironmentVariables();
  }

  private loadEnvironmentVariables(): AuthConfig {

    // Destructuring environment variables from process.env
    // Optional variables have default values
    const {
      REACT_APP_AUTH_CLIENT_ID,  // Required
      REACT_APP_AUTH_ENDPOINT,  // Required
      REACT_APP_AUTH_REDIRECT_URL = '/callback', // Optional with default
      REACT_APP_AUTH_SCOPE = '' // Optional with default
    } = process.env;

    if (REACT_APP_AUTH_CLIENT_ID === undefined) {
      throw new Error('REACT_APP_AUTH_CLIENT_ID is not set in environment variables.');
    }
    if (REACT_APP_AUTH_ENDPOINT === undefined) {
      throw new Error('REACT_APP_AUTH_ENDPOINT is not set in environment variables.');
    }
    if (REACT_APP_AUTH_REDIRECT_URL === undefined) {
      throw new Error('REACT_APP_AUTH_REDIRECT_URL is not set in environment variables.');
    }
    if (REACT_APP_AUTH_SCOPE === undefined) {
      throw new Error('REACT_APP_AUTH_SCOPE is not set in environment variables.');
    }

    // Assemble the configuration object
    return {
      clientId: REACT_APP_AUTH_CLIENT_ID,
      endpoint: REACT_APP_AUTH_ENDPOINT,
      redirectUri: REACT_APP_AUTH_REDIRECT_URL,
      scope: REACT_APP_AUTH_SCOPE
    };
  }

  public getAuthConfig(): AuthConfig {
    return this.authConfig;
  }
}

export default AuthService;
