import React from 'react'
import VpsData from '../../Models/Vps/VpsData'
import { Button, Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import { setVpsDetails, setWordpressVpsServers } from '../../actions'
import { Link } from 'react-router-dom'
import VpsService from '../../Services/VpsService'
import VpsLabelForm from '../../components/Form/Vps/VpsLabelForm'
import CopyClipboard from '../../components/Generic/CopyClipboard';

interface PropTypes {
  vpsDetails: VpsData | null
  setVpsDetails: (val: VpsData | null) => {}
  vpsWordpressServers: VpsData[] | null
  setWordpressVpsServers: (val: VpsData[] | null) => {}
}

interface StateTypes {
  showForm: boolean
}

class VpsOverview extends React.Component<PropTypes, StateTypes> {
  private readonly vpsService: VpsService

  constructor (props: PropTypes) {
    super(props)

    this.state = {
      showForm: false
    }

    this.vpsService = new VpsService()

    this.saveVpsDetails = this.saveVpsDetails.bind(this)
    this.copyToClipboard = this.copyToClipboard.bind(this)
    this.renderServers = this.renderServers.bind(this)
    this.toggleForm = this.toggleForm.bind(this)
  }

  componentDidMount (): void {
    this.props.setVpsDetails(null)

    this.vpsService.getAllWordpressVpsServers().then(servers => {
      this.props.setWordpressVpsServers(servers)
    }).catch(console.log)

    setInterval(() => {
      this.vpsService.getAllWordpressVpsServers().then(servers => {
        this.props.setWordpressVpsServers(servers)
      }).catch(console.log)
    }, 60000)
  }

  saveVpsDetails (server: VpsData): void {
    this.props.setVpsDetails(server)
  }

  copyToClipboard (value: string): void {
    navigator.clipboard.writeText(value).catch(console.log)
  }

  renderDateTime (date: string): string {
    const d = new Date(date)
    return d.toLocaleString('nl-NL', { timeZone: 'Europe/Amsterdam' })
  }

  toggleForm (): void {
    this.setState({ showForm: !this.state.showForm })
  }

  renderLoading (): JSX.Element | null {
    if (this.state.showForm) {
      return null
    }

    return (
            <div className="row" >
               <p>Loading servers...</p>
            </div>
    )
  }
  renderServers (): JSX.Element | null {
    const { vpsWordpressServers } = this.props
    if (this.state.showForm || vpsWordpressServers === null || vpsWordpressServers.length === 0) {
      return null
    }

    return (
        <div className="row" >
          { vpsWordpressServers?.map((server: VpsData, i) => {
            const status = server.labels.status ?? server.status
            return (
                <div className="col-xl-4 col-lg-6 col-md-12" key={i}>
                  <Card>
                    <Card.Body>
                      <Card.Title>{server.name}</Card.Title>
                      <Card.Text>
                        <strong>Status:</strong> {['initialize', 'finished'].includes(status) ? status : server.status}
                        <br />

                        <CopyClipboard title={'IPv4'} value={server.public_net.ipv4?.ip} />
                        <br />
                        <CopyClipboard title={'IPv6'} value={server.public_net.ipv6?.ip} />
                        {server.public_net.floating_ips.map((floatingIp, i) => {
                          return (
                              <CopyClipboard key={i} title={`floatingIP ${i}`} value={floatingIp} />
                          )
                        })}
                        <br />
                        <strong>Created on:</strong> {this.renderDateTime(server.created)}
                      </Card.Text>
                      <Link to={`/vps/wordpress/${server.id}`}>
                        <Button onClick={() => { this.saveVpsDetails(server) }} variant="primary">More details</Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>
            )
          })}
        </div>
    )
  }


  render (): JSX.Element {

    return (
            <div className="col-lg-12">
                    <div className="card">
                        <div className="header d-flex justify-content-between">
                            <h2>
                                VPS
                                <small>
                                    Products & Services overview
                                </small>
                            </h2>
                            <button onClick={() => { this.setState({ showForm: !this.state.showForm }) }}
                                    className={this.state.showForm ? 'btn btn-danger mr-3' : 'btn btn-primary mr-3'}>
                                {this.state.showForm ? 'Cancel' : 'Create VPS Server'}
                            </button>
                        </div>
                    </div>
                {
                    this.state.showForm
                      ? <VpsLabelForm serverType={'wordpress'} update={false} toggleForm={this.toggleForm} title={'Create Your Server:'} />
                      : null
                }
              {(this.props.vpsWordpressServers === null) ? this.renderLoading() : this.renderServers()}
                {/*{ this.renderServers() }*/}
            </div>
    )
  }
}

// @ts-expect-error
const mapStateToProps = ({ vpsReducer }): { vpsDetails: VpsData | null, vpsWordpressServers: VpsData[] | null } => ({
  vpsDetails: vpsReducer.vpsDetails,
  vpsWordpressServers: vpsReducer.vpsWordpressServers
})

export default connect(mapStateToProps, {
  setVpsDetails,
  setWordpressVpsServers
// @ts-expect-error
})(VpsOverview)
