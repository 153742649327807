import React from 'react'
import DatabaseForm from './DatabaseForm'
import DatabaseService from '../../Services/DatabaseService'
import DatabaseData from '../../Models/Database/DatabaseData'

interface StateTypes {
  databases: DatabaseData[]
  showForm: boolean
}
class DatabaseOverview extends React.Component<any, StateTypes> {
  private readonly databaseService: DatabaseService

  constructor (props: any) {
    super(props)

    this.state = {
      databases: [],
      showForm: false
    }

    this.databaseService = new DatabaseService()

    this.createDatabase = this.createDatabase.bind(this)
  }

  componentDidMount (): void {
    const domainUuid: string | null = localStorage.getItem('currentDomain')
    if (domainUuid === null) return
    this.databaseService.getAllDatabasesByDomain(domainUuid).then(databases => {
      this.setState({ databases })
    })
  }

  createDatabase (database: DatabaseData): void {
    const domainUuid: string | null = localStorage.getItem('currentDomain')
    if (domainUuid === null) return
    this.databaseService.createDatabase(database, domainUuid).then((database) => {
      this.setState({ databases: [...this.state.databases, database], showForm: false })
    })
  }

  renderDatabaseTable (): JSX.Element {
    return (
      <div className="table-responsive">
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>Database name</th>
              <th className="hidden-sm-down">Host</th>
              <th className="hidden-sm-down">Username</th>
            </tr>
          </thead>
          <tbody>
            {this.state.databases?.map(
              (database: DatabaseData, index: number) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{database.databaseName}</td>
                    <td>{database.host}</td>
                    <td>{database.username}</td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
      </div>
    )
  }

  render (): JSX.Element {
    return (
      <>
        <div className="header d-flex justify-content-between">
          <h1>Domain settings</h1>
          {this.state.showForm
            ? (
            <button
              className="btn btn-danger"
              onClick={() => this.setState({ showForm: !this.state.showForm })}
            >
              Cancel
            </button>
              )
            : (
            <button
              className="btn btn-success"
              onClick={() => this.setState({ showForm: !this.state.showForm })}
            >
              New
            </button>
              )}
        </div>
        <div className="body">
          <div className="row clearfix">
            <div className="col-md-12">
              {this.state.showForm
                ? (
                <DatabaseForm onSubmit={this.createDatabase} />
                  )
                : (
                    this.renderDatabaseTable()
                  )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default DatabaseOverview
