import {
  SET_INVOICE_DETAILS
} from '../actions'
import InvoiceData from '../Models/InvoiceData'

interface InvoiceReducerState {
  invoiceDetails: InvoiceData | null
}

const initialState: InvoiceReducerState = {
  invoiceDetails: null
}

interface Action {
  type: string
  payload: InvoiceData
}

const invoiceReducer = (state = initialState, action: Action): InvoiceReducerState => {
  switch (action.type) {
    case SET_INVOICE_DETAILS: {
      return {
        ...state,
        invoiceDetails: action.payload
      }
    }

    default:
      return state
  }
}

export default invoiceReducer
