import ApiService, { ApiResponse } from './ApiService'
import ProductData from '../Models/ProductData'

class ProductService {
  private readonly api: ApiService

  constructor () {
    this.api = new ApiService()
  }

  getProducts (): Promise<ProductData[]> {
    return new Promise((resolve, reject) => {
      this.api.apiCall('/api/v1/products').then((response: ApiResponse) => {
        return resolve(response.data)
      }).catch(reject)
    })
  }
}

export default ProductService
