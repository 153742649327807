import React, { ReactElement } from 'react';
import NavBarBreadcrumb from './Navbar/NavBarBreadcrumb';
import NavBarTop from './Navbar/NavBarTop';
import NavBarSide from './Navbar/NavBarSide';
import CustomerCreateModal from '../Modals/CustomerCreateModal';
import NavigationService from '../../Services/NavigationService';
import { connect } from 'react-redux';
import UserData from '../../Models/UserData';
import { onCurrentUserChange } from '../../actions';
import CustomerData from '../../Models/CustomerData';
import DarkModeService from '../../Services/DarkModeService';

interface LayoutProps {
  children: ReactElement;
  activeCustomer: CustomerData | null;
  currentUser: UserData | null;
}

class Layout extends React.Component<LayoutProps, any> {

  constructor(props: LayoutProps) {
    super(props);

    this.state = {
      menuItems: null
    };
  }

  componentDidMount(): void {
    const {currentUser} = this.props;
    if (currentUser === null) {
      return;
    }
    if(DarkModeService.checkForDarkModeFeature()) {
      document.body.classList.add('full-dark');
    }

    const menuItems = NavigationService.generateMenuStructure(currentUser);

    this.setState({
      menuItems
    });
  }

  renderCustomerCreateModal(): JSX.Element {
    return (
        <div id="wrapper">
          {/* Create Customer Modal */}
          <CustomerCreateModal closeButtonText={'close'} primaryButtonText={'Create'} title={'Create Customer'} />
        </div>
    );
  }

  renderMainContent(): JSX.Element {
    // only when user has active Customer
    if (this.props.activeCustomer === null) {
      return <></>
    }

    return (
        <div id="main-content">
          <div
              style={{flex: 1}}
              onClick={() => {
                document.body.classList.remove('offcanvas-active');
              }}
          >
            <div>
              <div className="container-fluid">
                <NavBarBreadcrumb menuItems={this.state.menuItems}/>

                {this.props.children}
              </div>
            </div>
          </div>
        </div>
    );
  }

  render(): JSX.Element | null {
    const {currentUser} = this.props;
    const {menuItems} = this.state;
    if (currentUser === null) {
      return null;
    }

    if (menuItems === null) {
      return null;
    }

    if(this.props.activeCustomer === null) {
      return this.renderCustomerCreateModal()
    }

    return (
        <div id="wrapper">
          {/* Top Navigation */}
          <NavBarTop/>

          {/* left Navigation */}
          <NavBarSide menuItems={menuItems}/>

          {/* Content Wrapper */}
          {this.renderMainContent()}
        </div>
    );
  }
}

// @ts-expect-error
const mapStateToProps = ({userReducer, customerReducer}): { currentUser: UserData | null, activeCustomer: CustomerData | null } => ({
  currentUser: userReducer.currentUser,
  activeCustomer: customerReducer.activeCustomer
});

export default connect(mapStateToProps, {
  onCurrentUserChange
})(Layout);
