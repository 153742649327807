import React from 'react'
import ResponseData from '../../Models/OpenProvider/ResponseData'
import DomainConfirmation from './DomainConfirmation'
import OrderComplete from '../Order/OrderComplete'
import Loading from '../../pages/Screens/Loading'

interface PropTypes {
  results: ResponseData[] | null
  onBack: () => void
}

interface StateTypes {
  orderSuccessful: boolean
  isLoading: boolean
}
class DomainResults extends React.Component<PropTypes, StateTypes> {
  constructor (props: any) {
    super(props)

    this.state = {
      orderSuccessful: false,
      isLoading: false
    }
  }

  render (): JSX.Element {
    return (
            <>
                {
                    this.state.isLoading
                      ? <Loading />
                      : this.state.orderSuccessful
                        ? <OrderComplete />
                        : <div className="container">
                                <u style={{ cursor: 'pointer' }} onClick={this.props.onBack}>Check more domains</u>
                                <ul className="list-group">
                                    {
                                        this.props.results?.map((value, i) => {
                                          return (

                                                <li key={i} className="list-group-item d-flex justify-content-between">
                                                    <div className="d-flex w-10-m justify-content-between" style={{ width: '11%' }}>
                                                        {
                                                            value.status === 'free'
                                                              ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                                     className="bi bi-check-circle" viewBox="0 0 16 16">
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                    <path
                                                                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                                                </svg>
                                                              : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                                     className="bi bi-dash-circle" viewBox="0 0 16 16">
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                                                </svg>
                                                        }
                                                        <p style={{ fontSize: '14px' }}>{value.domain.split('.')[0]}<b>.{value.domain.split('.')[1]}</b></p>
                                                    </div>

                                                    <div className="d-flex justify-content-between w-25">
                                                        <b>
                                                            {value.price.product.currency} {(value.price.product.price * 2).toFixed(2) }
                                                        </b>
                                                        <DomainConfirmation onSubmit={() => this.setState({ isLoading: true })} onSuccessful={() => this.setState({ orderSuccessful: true, isLoading: false })} domain={value} />
                                                    </div>
                                                </li>

                                          )
                                        })
                                    }
                                </ul>
                            </div>

                }
            </>

    )
  }
}

export default DomainResults
