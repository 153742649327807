import ApiService, { ApiResponse } from './ApiService'
import CustomerService from './CustomerService'
import ActiveSubscriptionData from '../Models/ActiveSubscriptionData'
import CustomerData from '../Models/CustomerData'

class ActiveSubscriptionService {
  private readonly api: ApiService
  private readonly customerService: CustomerService

  constructor () {
    this.api = new ApiService()
    this.customerService = new CustomerService()
  }

  getActiveSubscriptions (): Promise<ActiveSubscriptionData[]> {
    return new Promise((resolve, reject) => {
      this.customerService.getActiveCustomer().then((activeCustomer: CustomerData | null) => {
        if (activeCustomer === null) {
          return reject
        }

        const url = `/api/v1/customer/${activeCustomer.uuid}/activesubscription`

        this.api.apiCall(url).then((response: ApiResponse) => {
          return resolve(response.data)
        }).catch(reject)
      }).catch(reject)
    })
  }
}

export default ActiveSubscriptionService
