import React from 'react'
import ProductData from '../../Models/ProductData'
import ProductService from '../../Services/ProductService'
import SubscriptionData from '../../Models/SubscriptionData'
import SubscriptionService from '../../Services/SubscriptionService'
import Loading from '../Screens/Loading'
interface StateTypes {
  products?: ProductData[] | null
  subscriptions?: SubscriptionData[] | null
}

class ProductsOverview extends React.Component<any, StateTypes> {
  private readonly productService: ProductService
  private readonly subscriptionService: SubscriptionService

  constructor (props: any) {
    super(props)

    this.state = {
      products: null,
      subscriptions: null
    }

    this.productService = new ProductService()
    this.subscriptionService = new SubscriptionService()
  }

  componentDidMount (): void {
    this.productService.getProducts().then((products: ProductData[]) => {
      this.setState({ products })
    }).catch(console.log)
    this.subscriptionService.getSubscriptions().then((subscriptions: SubscriptionData[]) => {
      this.setState({ subscriptions })
    }).catch(console.log)
  }

  render (): JSX.Element {
    if (this.state.products === null && this.state.subscriptions === null) {
      return <Loading />
    }
    return (
           <div className="col-lg-12">
               <div className="card">
                   <div className="header">
                       <h2>
                           Products
                           <small>
                               Products & Services overview
                           </small>
                       </h2>
                   </div>
               </div>
               { this.state.subscriptions?.map((subscription, i) => {
                 return (
                       <div className="card" key={i}>
                           <div className="row no-gutters">
                               <div className="col-md-6 padding-25">
                                   <div className="card-body padding-25">
                                       <h5 className="card-title">{subscription.name}</h5>
                                       <p className="card-text"><small className="text-muted">Bizhost</small></p>
                                       <p className="card-text">{subscription.description}</p>
                                       <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small>
                                       </p>
                                   </div>
                               </div>
                               <div className="col-md-3 padding-25" >
                                   <div className="card-body">
                                       { subscription.specifications === null
                                         ? null
                                         : <h5 className="card-title">Specifications: </h5>
                                       }
                                       <ul>
                                           {subscription.specifications?.map((specification, i) => {
                                             return (
                                                   <li key={i}>{specification}</li>
                                             )
                                           })}
                                       </ul>
                                   </div>
                               </div>
                               <div className="col-md-3 padding-25">
                                   <div className="card-body">
                                       <h5 className="card-title">€ {subscription.price}</h5>
                                       <p className="card-text">per {subscription.period}</p>
                                       <a href="#" className="btn btn-primary">Add to cart</a>
                                   </div>
                               </div>
                           </div>
                       </div>
                 )
               })}
               { this.state.products?.map((product, i) => {
                 return (
                       <div className="card" key={i}>
                           <div className="row no-gutters">
                               <div className="col-md-6 padding-25">
                                   <div className="card-body">
                                       <h5 className="card-title">{product.productName}</h5>
                                       <p className="card-text"><small className="text-muted">{product.brand}</small></p>
                                       <p className="card-text">{product.description}</p>
                                       <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small>
                                       </p>
                                   </div>
                               </div>
                               <div className="col-md-3 padding-25" >
                                   <div className="card-body">
                                       {product.specifications === null
                                         ? null
                                         : <h5 className="card-title">Specifications: </h5>
                                       }
                                       <ul>
                                           {product.specifications?.map((specification, i) => {
                                             return (
                                                   <li key={i}>{specification}</li>
                                             )
                                           })}
                                       </ul>
                                   </div>
                               </div>
                               <div className="col-md-3 padding-25">
                                   <div className="card-body">
                                       <h5 className="card-title">€ {product.retailPrice}</h5>
                                       <p className="card-text">only {product.stock} left in stock</p>
                                       <a href="#" className="btn btn-primary">Add to cart</a>
                                   </div>
                               </div>
                           </div>
                       </div>
                 )
               })}
               <div className="card">
                   <div className="row no-gutters">
                       <div className="col-md-6 padding-25">
                           <div className="card-body">
                               <h5 className="card-title">Macbook Pro &apos;16 (2019)</h5>
                               <p className="card-text"><small className="text-muted">Apple</small></p>
                               <p className="card-text">De dunne en krachtige MacBook Air is een 13-inch-laptop met een Retina-display. Dit scherm zorgt voor een ongekende scherpte en accurate kleurweergave, waardoor je geniet van jouw favoriete films, series of foto’s. Ook de graphics van de MacBook Air zijn scherper en vloeiender dan ooit tevoren dankzij de Apple M1-chip. Deze chip zorgt met zijn 8-core CPU en 7-core GPU voor snelle prestaties en scherpe graphics. Zo werk je probleemloos met diverse veeleisende apps voor bijvoorbeeld foto- en videobewerking. Verder beschikt de laptop over twee Thunderbolt 3-poorten, waardoor je jouw randapparatuur eenvoudig kunt aansluiten. </p>
                               <p className="card-text"><small className="text-muted">Last updated 5 mins ago</small>
                               </p>
                           </div>
                       </div>
                       <div className="col-md-3" >
                           <div className="card-body">
                               <h5 className="card-title">Specifications: </h5>
                               <ul>
                                   <li></li>
                                   <li></li>
                                   <li></li>
                               </ul>
                           </div>
                       </div>
                       <div className="col-md-3">
                           <div className="card-body">
                               <h5 className="card-title">€ 2500</h5>
                               <p className="card-text">only 5 left in stock</p>
                               <a href="#" className="btn btn-primary">Add to cart</a>
                           </div>
                       </div>
                   </div>
               </div>

           </div>
    )
  }
}

export default ProductsOverview
