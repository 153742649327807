import React from 'react';
import Loading from '../../pages/Screens/Loading';
import UserData from '../../Models/UserData';
import { connect } from 'react-redux';
import { onActiveCustomerChange, onCurrentUserChange } from '../../actions';
import AccountService from '../../Services/AccountService';
import CustomerData from '../../Models/CustomerData';
import CustomerService from '../../Services/CustomerService';

interface PropTypes {
  children: React.ReactElement;
  currentUser: UserData | null;
  activeCustomer: CustomerData | null;
  onCurrentUserChange: (userData: UserData) => void;
  onActiveCustomerChange: (customerData: CustomerData) => void;
}

interface StateTypes {
  initialRender: boolean;
}

class CustomerProvider extends React.Component<PropTypes, StateTypes> {

  constructor(props: PropTypes | Readonly<PropTypes>) {
    super(props);

    this.state = {
      initialRender: false
    };

    this.handleFetchActiveCustomer = this.handleFetchActiveCustomer.bind(this)
  }

  componentDidMount(): void {
    this.setState({
      initialRender: true
    });
  }

  componentDidUpdate(prevProps: Readonly<PropTypes>, prevState: Readonly<StateTypes>): void {
    if (this.state.initialRender === prevState.initialRender && !this.state.initialRender) {
      return;
    }

    const user = this.props.currentUser ?? AccountService.getCurrentUserFromLocalStorage();

    if(user === null) {
      return;
    }

    if(this.props.activeCustomer === null) {
      this.handleFetchActiveCustomer()
    }
  }

  handleFetchActiveCustomer() {
    const customerService = new CustomerService();
    const accountService = new AccountService();
    const customers = accountService.getCustomersFromUser();

    if (customers != null) {
      const customer: CustomerData = customers[0]
      customerService.storeActiveCustomer(customer)
      this.props.onActiveCustomerChange(customer)
    }
  }

  render(): JSX.Element {
    if (!this.state.initialRender) {
      return <Loading />;
    }

    return this.props.children;
  }
}

// @ts-ignore
const mapStateToProps = ({ userReducer, customerReducer }): { currentUser: UserData | null, activeCustomer: CustomerData | null } => ({
  currentUser: userReducer.currentUser,
  activeCustomer: customerReducer.activeCustomer
})

export default connect(mapStateToProps, {
  onCurrentUserChange,
  onActiveCustomerChange
})(CustomerProvider)
