

class ToolsService {
  public static capitalizeFirstLetter (string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  public static navigateTo (path: string): void {
    window.location.href = path
  }
}

export default ToolsService
