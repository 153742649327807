import { combineReducers } from 'redux'
import loginReducer from './loginReducer'
import userReducer from './userReducer'
import customerReducer from './customerReducer'
import navigationReducer from './navigationReducer'
import modalReducer from './modalReducer'
import analyticalReducer from './analyticalReducer'
import vpsReducer from './vpsReducer'
import quoteReducer from './quoteReducer'
import invoiceReducer from './invoiceReducer'
import domainReducer from './domainReducer'

export default combineReducers({
  loginReducer,
  userReducer,
  customerReducer,
  navigationReducer,
  modalReducer,
  analyticalReducer,
  vpsReducer,
  quoteReducer,
  invoiceReducer,
  domainReducer
})
