import DatabaseData from '../Models/Database/DatabaseData'
import ApiService from './ApiService'
import WebHostingService from './WebHostingService'

class DatabaseService {
  private readonly api: ApiService
  private readonly webHostingService: WebHostingService
  constructor () {
    this.api = new ApiService()
    this.webHostingService = new WebHostingService()
  }

  createDatabase (database: DatabaseData, domainUuid: string): Promise<DatabaseData> {
    return new Promise((resolve, reject) => {
      this.webHostingService.getWebHostingByDomain(domainUuid)
        .then(hosting => {
          database.webHosting = hosting?.uuid
          this.api.apiCall('/api/v1/database', 'POST', JSON.stringify(database))
            .then(response => {
              if (response.status === 200) {
                return resolve(response.data)
              }
              return reject(new Error(response.meta.error?.message))
            }).catch(reject)
        }).catch(reject)
    })
  }

  getAllDatabasesByDomain (domainUuid: string): Promise<DatabaseData[]> {
    return new Promise((resolve, reject) => {
      this.api.apiCall(`/api/v1/domain/${domainUuid}/database`).then(response => {
        if (response.status === 200) {
          return resolve(response.data)
        }

        return reject(new Error(response.meta.error?.message))
      }).catch(reject)
    })
  }
}

export default DatabaseService
