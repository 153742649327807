import React from 'react'
import DnsRecordForm from './DnsRecordForm'
import DnsRecordOverview from './DnsRecordOverview'
import DnsRecordData from '../../Models/Dns/DnsRecordData'
import DnsZoneData from '../../Models/DnsZoneData'
import DnsService from '../../Services/DnsService'
import { connect } from 'react-redux'
import { setDomainDetails } from '../../actions'
import DomainData from '../../Models/DomainData'

interface PropTypes {
  domainDetails: DomainData | null
  setDomainDetails: (val: DomainData | null) => {}
}
interface StateTypes {
  showForm: boolean
  dnsZone: DnsZoneData | null
}

class DnsZoneOverview extends React.Component<PropTypes, StateTypes> {
  private readonly dnsService: DnsService
  constructor (props: any) {
    super(props)

    this.dnsService = new DnsService()

    this.state = {
      showForm: false,
      dnsZone: null
    }

    this.createDnsRecord = this.createDnsRecord.bind(this)
  }

  componentDidMount (): void {
    const domainUuid: string | null = localStorage.getItem('currentDomain')
    if (domainUuid === null) return

    this.dnsService.getDnsZoneByDomain(domainUuid).then(dnsZone => {
      this.setState({ dnsZone })
    })
  }

  createDnsRecord (dnsRecord: DnsRecordData): void {
    dnsRecord.dnsZone = this.state.dnsZone?.uuid
    this.dnsService.createDnsRecord(dnsRecord).then(response => {
      if (response.status === 200) {
        if (this.props.domainDetails?.uuid === undefined) return
        this.dnsService
          .getDnsZoneByDomain(this.props.domainDetails?.uuid)
          .then(dnsZone => {
            this.setState({ dnsZone, showForm: false })
          })
      }
    })
  }

  render (): JSX.Element {
    return (
      <>
        <div className="header d-flex justify-content-between">
          <h1>Domain settings</h1>
          {this.state.showForm
            ? (
            <button
              className="btn btn-danger"
              onClick={() => this.setState({ showForm: !this.state.showForm })}
            >
              Cancel
            </button>
              )
            : (
            <button
              className="btn btn-success"
              onClick={() => this.setState({ showForm: !this.state.showForm })}
            >
              New
            </button>
              )}
        </div>
        <div className="body">
          <div className="row clearfix">
            <div className="col-md-12">
              {this.state.showForm
                ? (
                <DnsRecordForm onSubmit={this.createDnsRecord} />
                  )
                : (
                <DnsRecordOverview dnsRecords={this.state.dnsZone?.dnsRecords} />
                  )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

// @ts-expect-error
const mapStateToProps = ({ domainReducer }): { domainDetails: any } => ({
  domainDetails: domainReducer.domainDetails
})

export default connect(mapStateToProps, {
  setDomainDetails
})(DnsZoneOverview)
