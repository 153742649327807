export const SET_VPS_DETAILS = 'vpsReducer/SET_VPS_DETAILS'
export const SET_GENERIC_VPS_SERVERS = 'vpsReducer/SET_GENERIC_VPS_SERVERS'
export const SET_LINKED_HELPER_VPS_SERVERS = 'vpsReducer/SET_LINKED_HELPER_VPS_SERVERS'
export const SET_WORDPRESS_VPS_SERVERS = 'vpsReducer/SET_WORDPRESS_VPS_SERVERS'
export const SET_VPS_PERIOD = 'vpsReducer/SET_VPS_PERIOD'

export const setVpsDetails = (val) => (dispatch) => {
  dispatch({
    type: SET_VPS_DETAILS,
    payload: val
  })
}
export const setGenericVpsServers = (val) => (dispatch) => {
  dispatch({
    type: SET_GENERIC_VPS_SERVERS,
    payload: val
  })
}
export const setLinkedHelperVpsServers = (val) => (dispatch) => {
  dispatch({
    type: SET_LINKED_HELPER_VPS_SERVERS,
    payload: val
  })
}
export const setWordpressVpsServers = (val) => (dispatch) => {
  dispatch({
    type: SET_WORDPRESS_VPS_SERVERS,
    payload: val
  })
}
export const setPeriod = (val) => (dispatch) => {
  dispatch({
    type: SET_VPS_PERIOD,
    payload: val
  })
}
