import React from 'react'
import { connect } from 'react-redux'
import { setDomainDetails } from '../../actions'
import DomainData from '../../Models/DomainData'
import DomainService from '../../Services/DomainService'
import ToolsService from '../../Services/ToolsService'
import DnsService from '../../Services/DnsService'
import DnsZoneData from '../../Models/DnsZoneData'
import DomainSettings from '../../components/Domain/DomainSettings'

interface PropTypes {
  domainDetails: DomainData | null
  setDomainDetails: (val: DomainData | null) => {}
}

interface StateTypes {
  showForm: boolean
  dnsZone: DnsZoneData | null
}
class DomainDetails extends React.Component<PropTypes, StateTypes> {
  private readonly domainService: DomainService
  private readonly dnsService: DnsService
  constructor (props: PropTypes) {
    super(props)

    this.state = {
      showForm: false,
      dnsZone: null
    }

    this.domainService = new DomainService()
    this.dnsService = new DnsService()
  }

  componentDidMount (): void {
    if (this.props.domainDetails === null) {
      const domainUuid: string | null = localStorage.getItem('currentDomain')
      if (domainUuid === null) return
      this.domainService.getDomainByUuid(domainUuid).then((domain: DomainData | null) => {
        if (domain === null) {
          ToolsService.navigateTo('/domain/overview')
        }
        this.props.setDomainDetails(domain)
      })
    }
  }

  deleteDomain (): void {
    if (this.props.domainDetails?.uuid !== undefined) {
      this.domainService
        .cancelDomainSubscription(this.props.domainDetails?.uuid)
        .then(() => {
          ToolsService.navigateTo('/domain/overview')
        })
        .catch(console.log)
    }
  }

  renderContact (): JSX.Element {
    return (
      <>
        <div className="header">
          <h1> Contact details </h1>
        </div>

        <div className="body" style={{ width: '50%' }}>
          <div className="form-group mb3">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                value={'Name'}
                disabled
              />
              <input
                className="form-control"
                type="text"
                value={'Joopie'}
                disabled
              />
            </div>
          </div>
          <div className="form-group mb3">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                value={'Email'}
                disabled
              />
              <input
                className="form-control"
                type="text"
                value={'joopie@boopie.com'}
                disabled
              />
            </div>
          </div>
          <div className="form-group mb3">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                value={'Address'}
                disabled
              />
              <input
                className="form-control"
                type="text"
                value={'Julianaweg 200'}
                disabled
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  render (): JSX.Element {
    return (
      <div className="col-lg-12">
        <div className="card">
          <div className="header d-flex justify-content-between">
            <h2>
              {this.props.domainDetails?.domainName}.
              {this.props.domainDetails?.tld}
            </h2>
          </div>
        </div>
        <div className="card d-flex justify-content-around align-items-center">
          {this.renderContact()}
        </div>

        <div className="card">
          <DomainSettings />
        </div>

        <div className="card">
          <div className="header">
            <h5 style={{ color: '#b30000' }}>Cancel this domain?</h5>
            <small>
              <u>
                <b>WARNING!</b>
              </u>{' '}
              This action can not be reversed
            </small>
          </div>
          <div className="body">
            <button
              className="btn btn-danger"
              onClick={() => this.deleteDomain()}
            >
              Delete domain
            </button>
          </div>
        </div>
      </div>
    )
  }
}

// @ts-expect-error
const mapStateToProps = ({ domainReducer }): { domainDetails: any } => ({
  domainDetails: domainReducer.domainDetails
})

export default connect(mapStateToProps, {
  setDomainDetails
// @ts-expect-error
})(DomainDetails)
