import React from 'react'
import { Link } from 'react-router-dom'

class VpsOverview extends React.Component {

  render (): JSX.Element {

    return (
        <div className="row clearfix">

          <div className="col-lg-12">
            <div className="card">
              <div className="header d-flex justify-content-between">
                <h2>
                  VPS
                  <small>
                    Overview of all your virtual private servers and managed services
                  </small>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card">
              <div className="header d-flex justify-content-between">
                <h2>
                  Managed Wordpress
                  <small>
                    Manage all your Wordpress installations
                  </small>
                </h2>
              </div>

              <div className="body">
                <ul className="nav nav-tabs-new">
                  <li className="nav-item  mr-1">
                    <Link to="/vps/wordpress/overview/" className="nav-link active">Overview</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card">
              <div className="header d-flex justify-content-between">
                <h2>
                  VPS
                  <small>
                    Manage all your virtual private servers
                  </small>
                </h2>
              </div>

              <div className="body">
                <ul className="nav nav-tabs-new">
                  <li className="nav-item  mr-1">
                    <Link to="/vps/generic/overview/" className="nav-link active">Overview</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default VpsOverview
