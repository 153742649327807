import React from 'react'
import { connect } from 'react-redux'
import UserData from '../../../Models/UserData'
import NavBarSideCustomerDetails from './NavBarSideCustomerDetails'

interface PropTypes {
  currentUser: UserData
  currentUserLoaded: boolean
}

const renderName = (user: UserData, loading: boolean): JSX.Element | string => {
  if (!loading) {
    return 'Loading'
  }

  return (
      <>
        {user.firstName}
        {' '}{user.insertion}{user?.insertion !== null ? ' ' : null}
        {user.lastName}
      </>
  )
}

const renderCustomer = (user: UserData, loading: boolean): JSX.Element | string => {
  if (!loading) {
    return 'Loading'
  }

  return (
      <>
          <NavBarSideCustomerDetails customers={user.customers} />
          <br/>
        <hr/>
      </>
  )
}

const renderActiveProperties = (user: UserData, loading: boolean): JSX.Element | string => {
  if (!loading) {
    return 'Loading'
  }

  return (
      <>
        <ul className="row list-unstyled">
          <li className="col-3">
            <small>Domains</small>
            <h6>36</h6>
          </li>
          <li className="col-3">
            <small>Servers</small>
            <h6>5</h6>
          </li>
          <li className="col-6">
            <small>This month</small>
            <h6>€213</h6>
          </li>
        </ul>
        <hr/>
      </>
  )
}

const NavBarSideUserDetails = (props: PropTypes): JSX.Element => {
  return (
      <div className="user-account">
        <p style={{ marginBottom: '0px' }}>
          <span>Welcome,</span>
        </p>
        <strong>
          { renderName(props.currentUser, props.currentUserLoaded) }
        </strong>
        <br/>
        <br/>
        { renderCustomer(props.currentUser, props.currentUserLoaded)}
        { renderActiveProperties(props.currentUser, props.currentUserLoaded)}
      </div>
  )
}

// @ts-expect-error
const mapStateToProps = ({ userReducer }): { currentUser: UserData | null, currentUserLoaded: boolean } => ({
  currentUser: userReducer.currentUser,
  currentUserLoaded: userReducer.loaded
})

// @ts-expect-error
export default connect(mapStateToProps, {})(NavBarSideUserDetails)
