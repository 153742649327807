import React from 'react'
import DomainService from '../../Services/DomainService'
import DomainResults from '../../components/Domain/DomainResults'
import CheckDomainForm from '../../components/Domain/CheckDomainForm'
import ResponseData from '../../Models/OpenProvider/ResponseData'
import Loading from '../Screens/Loading'

interface StateTypes {
  submit: boolean
  domainData: ResponseData[] | null
  domainToSearch: string
  isLoading: boolean
}
class CheckDomain extends React.Component<any, StateTypes> {
  private readonly domainService: DomainService

  constructor (props: any) {
    super(props)

    this.domainService = new DomainService()

    this.state = {
      submit: false,
      domainData: null,
      domainToSearch: '',
      isLoading: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onSubmit (): void {
    this.setState({ isLoading: true })
    this.domainService.checkForDomain(this.state.domainToSearch).then(response => {
      this.setState({ submit: true, domainData: response, isLoading: false })
    }).catch(console.log)
  }

  onChange (e: React.FormEvent<HTMLInputElement>): void {
    this.setState({ domainToSearch: e.currentTarget.value })
  }

  render (): JSX.Element {
    return (
            <>
                {
                    this.state.isLoading
                      ? <Loading />
                      : this.state.submit
                        ? <DomainResults onBack={() => { this.setState({ submit: false }) } } results={this.state.domainData} />
                        : <CheckDomainForm onChange={this.onChange} onSubmit={this.onSubmit} />
                }
            </>
    )
  }
}

export default CheckDomain
