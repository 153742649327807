import React from 'react'

interface PropTypes {
  onSubmit: () => void
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
}
class CheckDomainForm extends React.Component<PropTypes, any> {
  render (): JSX.Element {
    return (
            <div className="container">
                <div className="row mb-5">
                    <div className="col-lg-8 mx-auto">
                        <div className="bg-white p-5 rounded shadow">
                            <div>
                                <h3>Is your domain available?</h3>
                                <br />
                                <div className="input-group mb-4">
                                    <input type="search" placeholder="Search for domain..."
                                           aria-describedby="button-addon5" className="form-control"
                                           onChange={this.props.onChange} />
                                    <div className="input-group-append">
                                        <button id="button-addon5" className="btn btn-primary" onClick={this.props.onSubmit}>
                                            <i className="fa fa-search"> </i>
                                        </button>
                                    </div>
                                </div>
                                <b>Examples of available domain extensions:</b><br /><hr />
                                <span className="badge badge-secondary">.nl</span>
                                <span className="badge badge-secondary">.com</span>
                                <span className="badge badge-secondary">.org</span>
                                <span className="badge badge-secondary">.net</span>
                                <span className="badge badge-secondary">.be</span><hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
  }
}

export default CheckDomainForm
