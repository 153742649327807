import React from 'react'
import { Link } from 'react-router-dom'
import MenuStructure from '../../../Models/MenuStructure'
import NavigationService from '../../../Services/NavigationService'

let toggleMenu = false

const onToggleMenu = (toggle: boolean): void => {
  toggleMenu = toggle

  if (!toggle) {
    document.body.classList.remove('layout-fullwidth')
  } else {
    document.body.classList.add('layout-fullwidth')
  }
}

export default function NavBarBreadcrumb ({ menuItems }: { menuItems: MenuStructure[] }): JSX.Element {
  const { activeMenuItem, activeParentMenuItem } = NavigationService.getActiveMenuItem(menuItems)

  return (
      <div className="block-header">
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <h2>
              <Link
                  to={activeMenuItem?.routeUrl ?? '/'}
                  className="btn btn-xs btn-link btn-toggle-fullwidth"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    e.preventDefault()
                    onToggleMenu(!toggleMenu)
                  }}
              >
                <i
                    className={
                      !toggleMenu
                          ? 'fa fa-arrow-left'
                          : 'fa fa-arrow-right'
                    }
                ></i>
              </Link>{' '}
              { activeMenuItem?.routeName ?? 'Home' }
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">
                  <i className="icon-home"></i>
                </Link>
              </li>
              { activeParentMenuItem !== null
                  ? <li className="breadcrumb-item active">
                    <Link to={activeParentMenuItem?.routeUrl ?? '/'} >
                      {activeParentMenuItem?.routeName ?? 'Home'}
                    </Link>
                  </li>
                  : null
              }
              { activeMenuItem !== activeParentMenuItem && activeParentMenuItem !== null
                  ? <li className="breadcrumb-item active">
                    <Link to={activeMenuItem?.routeUrl ?? '/'} >
                      {activeMenuItem?.routeName ?? 'Home'}
                    </Link>
                  </li>
                  : null
              }
            </ul>
          </div>

        </div>
      </div>
  )
}
