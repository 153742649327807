import React from 'react'
import { Button } from 'react-bootstrap'
import DatabaseData from '../../Models/Database/DatabaseData'

interface PropTypes {
  onSubmit: (val: DatabaseData) => void
}

interface StateTypes {
  databaseName: string
  username: string
  password: string
}

class DatabaseForm extends React.Component<PropTypes, StateTypes> {
  render (): JSX.Element {
    return (
            <>
                <label>Database name: </label>
                <div className="input-group mb-3">
                    <input
                        name="databaseName"
                        aria-describedby="basic-addon1"
                        aria-label="databaseName"
                        className="form-control"
                        placeholder="Database name"
                        type="text"
                        onChange={(event) => this.setState({ databaseName: event.target.value })}
                    />
                </div>
                <label>Username: </label>
                <div className="input-group mb-3">
                    <input
                        name="username"
                        id="username"
                        aria-describedby="basic-addon1"
                        aria-label="username"
                        className="form-control"
                        placeholder="Username"
                        type="text"
                        onChange={(event) => this.setState({ username: event.target.value })}
                    />
                </div>
                <label>Password: </label>
                <div className="input-group mb-3">
                    <input
                        name="password"
                        id="password"
                        aria-describedby="basic-addon1"
                        aria-label="value"
                        className="form-control"
                        placeholder="Value"
                        title="No spaces or special characters allowed"
                        type="password"
                        onChange={(event) => this.setState({ password: event.target.value })}
                    />
                </div>
                <Button onClick={() => this.props.onSubmit({
                  databaseName: this.state.databaseName,
                  username: this.state.username,
                  password: this.state.password,
                  host: 'localhost'
                })} variant="primary">
                    Submit
                </Button>
            </>
    )
  }
}

export default DatabaseForm
