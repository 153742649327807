import React from 'react'
import Layout from '../../components/public/Layout'

class Page403 extends React.Component {
  renderTitle (): JSX.Element {
    return (
        <h3>
          <span className="clearfix title">
            <span className="number left">403</span>{' '}
            <span className="text">Forbidden Error!</span>
          </span>
        </h3>
    )
  }

  renderBody (): JSX.Element {
    return (
        <>
          <p>You don&apos;t have permission to access / on this server.</p>
          <div className="margin-top-30">
            <a className="btn btn-default" onClick={() => {
              window.history.go(-1)
              return false
            }}>
              <i className="fa fa-arrow-left"></i>{' '}
              <span >Go Back</span>
            </a>{' '}
            <a className="btn btn-primary" href="/dashboard">
              <i className="fa fa-home"></i>{' '}
              <span >Home</span>
            </a>
          </div>
        </>

    )
  }

  render (): JSX.Element {
    return (
        <Layout
            title={this.renderTitle()}
            forgotPassword={false}
            register={false}
            login={false}
            forgotPasswordLogin={false}
            loading={false}
            children={this.renderBody()}
        />
    )
  }
}

export default Page403
