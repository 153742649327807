import {
  SET_GENERIC_VPS_SERVERS,
  SET_LINKED_HELPER_VPS_SERVERS,
  SET_VPS_DETAILS,
  SET_VPS_PERIOD,
  SET_WORDPRESS_VPS_SERVERS
} from '../actions';
import VpsData from '../Models/Vps/VpsData'

interface VpsReducerState {
  vpsDetails: VpsData | null
  vpsGenericServers: VpsData[] | null
  vpsLinkedHelperServers: VpsData[] | null
  vpsWordpressServers: VpsData[] | null
  period: string
}

const initialState: VpsReducerState = {
  vpsDetails: null,
  vpsGenericServers: null,
  vpsLinkedHelperServers: null,
  vpsWordpressServers: null,
  period: '15m'
}

interface Action {
  type: string
  payload: VpsData | VpsData[] | string
}

const vpsReducer = (state: VpsReducerState = initialState, action: Action): VpsReducerState => {
  switch (action.type) {
    case SET_VPS_DETAILS: {
      return {
        ...state,
        vpsDetails: action.payload as VpsData
      }
    }

    case SET_GENERIC_VPS_SERVERS: {
      return {
        ...state,
        vpsGenericServers: action.payload as VpsData[]
      }
    }

    case SET_LINKED_HELPER_VPS_SERVERS: {
      return {
        ...state,
        vpsLinkedHelperServers: action.payload as VpsData[]
      }
    }

    case SET_WORDPRESS_VPS_SERVERS: {
      return {
        ...state,
        vpsWordpressServers: action.payload as VpsData[]
      }
    }

    case SET_VPS_PERIOD: {
      return {
        ...state,
        period: action.payload as string
      }
    }

    default:
      return state
  }
}

export default vpsReducer
