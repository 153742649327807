import React from 'react'
import InvoiceData from '../../Models/InvoiceData'
import InvoiceService from '../../Services/InvoiceService'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {setInvoiceDetails} from '../../actions'
import {Card} from 'react-bootstrap'
import ListTileSwitch from '../../components/Overview/ListTileSwitch'

interface PropTypes {
    invoiceDetails: InvoiceData | null
    setInvoiceDetails: (val: InvoiceData | null) => {}
}

interface StateTypes {
    invoices: InvoiceData[] | null
    listFormat: boolean
}

class LastInvoices extends React.Component<PropTypes, StateTypes> {
    private readonly invoiceService: InvoiceService

    constructor(props: PropTypes) {
        super(props)

        this.state = {
            invoices: null,
            listFormat: true
        }

        this.invoiceService = new InvoiceService()

        this.renderTiles = this.renderTiles.bind(this)
        this.renderList = this.renderList.bind(this)
    }

    componentDidMount(): void {
        this.invoiceService.getInvoicesFromCustomer().then((invoices: InvoiceData[]) => {
            this.invoiceService.filterRecentInvoices(invoices).then((filteredInvoices: InvoiceData[]) => {
                this.setState({invoices: filteredInvoices})
            }).catch(console.log)
        }).catch(console.log)
    }

    saveInvoiceData(invoice: InvoiceData): void {
        this.props.setInvoiceDetails(invoice)
    }

    renderList(): JSX.Element {
        return (
            <div className="body table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        <th>INVOICE STATUS</th>
                        <th>CURRENCY</th>
                        <th>TOTAL AMOUNT</th>
                        <th>TOTAL AMOUNT INCL VAT</th>
                        <th>PAY DATE</th>
                        {/*<th>DUE DATE</th>*/}
                        <th>IS RECURRING</th>
                        <th>...</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.invoices?.map((invoice, i) => {
                        return (
                            <tr key={i}>
                                <td>{invoice.invoiceStatus}</td>
                                <td>{invoice.currency}</td>
                                <td>{invoice.totalAmount}</td>
                                <td>{invoice.totalAmountInclVat}</td>
                                <td>{invoice.payDate}</td>
                                {/*<td>{invoice.dueDate}</td>*/}
                                <td>{invoice.isRecurring ? 'Yes' : 'No'}</td>
                                <td><Link to='/finance/invoices/details'>
                                    <button onClick={() => {
                                        this.saveInvoiceData(invoice)
                                    }} className='btn btn-primary'>More details
                                    </button>
                                </Link></td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    renderTiles(): JSX.Element {
        return (
            <>
                <div className="row">
                    {this.state.invoices?.map((invoice, index) => {
                        return (
                            <div className="col-xl-4 col-lg-6 col-md-12" key={index}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>{index + 1}</Card.Title>
                                        <Card.Text>
                                            <strong>Status:</strong> {invoice.invoiceStatus}
                                            <br/>
                                            <strong>Currency: </strong>
                                            <span>{invoice.currency ?? 'not available'}</span>
                                            <br/>
                                            <strong>Total Amount: </strong>
                                            <span>{invoice.totalAmount ?? 'not available'}</span>
                                            <br/>
                                            <strong>Total Amount Incl Vat: </strong>
                                            <span>{invoice.totalAmountInclVat ?? 'not available'}</span>
                                            <br/>
                                            <strong>Pay Date: </strong>
                                            <span>{invoice.payDate ?? 'not available'}</span>
                                            <br/>
                                            <strong>Due Date: </strong>
                                            <span>{invoice.dueDate ?? 'not available'}</span>
                                            <br/>
                                            <strong>Is Recurring: </strong>
                                            <span>{invoice.isRecurring ? 'Yes' : 'No'}</span>
                                            <br/>
                                        </Card.Text>
                                        <Link to='/finance/invoices/details'>
                                            <button onClick={() => {
                                                this.saveInvoiceData(invoice)
                                            }} className='btn btn-primary'>
                                                More details
                                            </button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }

    render(): JSX.Element {
        return (
            <div className="col-lg-12">
                <div className="card">
                    <div className="header d-flex justify-content-between">
                        <h2>
                            My Last Invoices
                            <small>
                                My invoices of the last 3 months
                            </small>
                        </h2>
                        <ListTileSwitch setList={() => {
                            this.setState({listFormat: true})
                        }} setTile={() => {
                            this.setState({listFormat: false})
                        }}/>
                    </div>
                    {
                        this.state.listFormat
                            ? this.renderList()
                            : null
                    }
                </div>
                {
                    !this.state.listFormat
                        ? this.renderTiles()
                        : null
                }
            </div>
        )
    }
}

// @ts-expect-error
const mapStateToProps = ({invoiceReducer}): { invoiceDetails: InvoiceData | null } => ({
    invoiceDetails: invoiceReducer.invoiceDetails
})

export default connect(mapStateToProps, {
    setInvoiceDetails
// @ts-expect-error
})(LastInvoices)
