import ApiService, { ApiResponse } from './ApiService'
import RequestData from '../Models/OpenProvider/RequestData'
import ResponseData from '../Models/OpenProvider/ResponseData'
import DomainData from '../Models/DomainData'
import CustomerService from './CustomerService'
import ActiveSubscriptionData from '../Models/ActiveSubscriptionData'

class DomainService {
  private readonly api: ApiService

  constructor () {
    this.api = new ApiService()
  }

  checkForDomain (domain: string): Promise<ResponseData[]> {
    return new Promise((resolve, reject) => {
      const domainName: string = domain.split('.')[0]
      let extension: string = domain.split('.')[1]

      if (extension === undefined || extension === '') {
        extension = 'com'
      }
      const requestData: RequestData = {
        name: domainName,
        extension
      }

      this.api.apiCall('/api/v1/domain/check', 'POST', JSON.stringify(requestData)).then((response: ApiResponse) => {
        return resolve(response.data)
      }).catch(reject)
    })
  }

  registerDomain (domainData: DomainData): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      this.api.apiCall('/api/v1/domain', 'POST', JSON.stringify(domainData)).then(response => {
        return resolve(response)
      }).catch(reject)
    })
  }

  static OpenProviderResToDomainData (openProviderRes: ResponseData): Promise<DomainData> {
    return new Promise((resolve, reject) => {
      try {
        const domainData: DomainData = {
          domainName: openProviderRes.domain.split('.')[0],
          tld: openProviderRes.domain.split('.')[1],
          authCode: null,
          isDnsOnly: false,
          isTransferLocked: false,
          isWhiteLabeled: false,
          tags: []

        }

        return resolve(domainData)
      } catch (e) {
        return reject
      }
    })
  }

  getDomainsFromCustomer (): Promise<DomainData[]> {
    return new Promise((resolve, reject) => {
      const customerUuid: string | null = CustomerService.getActiveCustomerUuid()
      if (customerUuid === null) return reject(new Error('Customer could not be found'))
      this.api.apiCall(`/api/v1/${customerUuid}/activesubscription`).then(response => {
        const domains: DomainData[] = []

        response.data.forEach((subscription: ActiveSubscriptionData) => {
          if (subscription.domain != null) {
            domains.push(subscription.domain)
          }
        })
        return resolve(domains)
      }).catch(reject)
    })
  }

  async cancelDomainSubscription (domainUuid: string): Promise<void> {
    await this.api.apiCall(`/api/v1/domain/${domainUuid}`, 'DELETE')
  }

  getDomainByUuid (domainUuid: string): Promise<DomainData | null> {
    return new Promise((resolve, reject) => {
      this.api.apiCall(`/api/v1/domain/${domainUuid}`).then(response => {
        if (response.status === 200) {
          return resolve(response.data)
        }

        return resolve(null)
      }).catch(reject)
    })
  }
}

export default DomainService
