export const ON_LOGGED_IN = 'loginReducer/ON_LOGGED_IN'
export const ON_LOCKED = 'loginReducer/ON_LOCKED'
export const ON_ERROR = 'loginReducer/ON_ERROR'
export const SET_ERROR_MESSAGE = 'loginReducer/SET_ERROR_MESSAGE'
export const SET_SUCCESS_MESSAGE = 'loginReducer/SET_SUCCESS_MESSAGE'
export const ON_SUCCESS = 'loginReducer/ON_SUCCESS'

export const onLoggedIn = (val) => (dispatch) => {
  dispatch({
    type: ON_LOGGED_IN,
    payload: val
  })
}

export const onLocked = (val) => (dispatch) => {
  dispatch({
    type: ON_LOCKED,
    payload: val
  })
}

export const onError = (val) => (dispatch) => {
  dispatch({
    type: ON_ERROR,
    payload: val
  })
}

export const setErrorMessage = (val) => (dispatch) => {
  dispatch({
    type: SET_ERROR_MESSAGE,
    payload: val
  })
}

export const setSuccessMessage = (val) => (dispatch) => {
  dispatch({
    type: SET_SUCCESS_MESSAGE,
    payload: val
  })
}

export const onSuccess = (val) => (dispatch) => {
  dispatch({
    type: ON_SUCCESS,
    payload: val
  })
}
