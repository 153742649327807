import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { onModalShow, setPeriod, setVpsDetails } from '../../actions'
import { Navigate } from 'react-router-dom'
import VpsData from '../../Models/Vps/VpsData'
import VpsService from '../../Services/VpsService'
import VpsChart from '../../components/Chart/VpsChart'
import ConfirmationModal from '../../components/Modals/ConfirmationModal'
import { ApiResponse } from '../../Services/ApiService'
import CurrencyService from '../../Services/CurrencyService'
import VpsLabelForm from '../../components/Form/Vps/VpsLabelForm'
import CopyClipboard from '../../components/Generic/CopyClipboard';

interface PropTypes {
  vpsDetails: VpsData
  setVpsDetails: (val: VpsData | null) => {}
  period: string
  setPeriod: (val: string) => {}
  show: boolean
  onModalShow: (val: boolean) => {}
}

interface StateTypes {
  isConfirmed: boolean
  currentAction: string | null
  editLabels: boolean
  labels: object
}

class VpsDetails extends React.Component<PropTypes, StateTypes> {
  private readonly vpsService: VpsService
  constructor (props: PropTypes) {
    super(props)

    this.state = {
      isConfirmed: false,
      currentAction: null,
      editLabels: false,
      labels: {}
    }

    this.vpsService = new VpsService()

    this.startServer = this.startServer.bind(this)
    this.restartServer = this.restartServer.bind(this)
    this.stopServer = this.stopServer.bind(this)
    this.deleteServer = this.deleteServer.bind(this)
    this.refreshDetails = this.refreshDetails.bind(this)
    this.setVpsPeriod = this.setVpsPeriod.bind(this)
    this.isConfirmed = this.isConfirmed.bind(this)
    this.copyToClipboard = this.copyToClipboard.bind(this)
    this.downloadConfig = this.downloadConfig.bind(this)
    this.calculateCurrentPrice = this.calculateCurrentPrice.bind(this)
    this.calculateMonthlyPrice = this.calculateMonthlyPrice.bind(this)
    this.renderLabels = this.renderLabels.bind(this)
    this.editLabels = this.editLabels.bind(this)
    this.onChangeLabel = this.onChangeLabel.bind(this)
  }

  componentDidMount (): void {
    setInterval(this.refreshDetails, 60000)
  }

  refreshDetails (): void {
    this.vpsService.findVpsServer(this.props.vpsDetails.id).then(server => {
      this.props.setVpsDetails(server)
      this.setState({ labels: server.labels })
    }).catch(console.log)
  }

  startServer (): void {
    this.vpsService.startVpsServer(this.props.vpsDetails.id).then(response => {
      this.vpsService.findVpsServer(this.props.vpsDetails.id).then(server => {
        this.props.setVpsDetails(server)
      }).catch(console.log)
    }).catch(console.log)
  }

  restartServer (confirmed: boolean): void {
    this.setState({ currentAction: 'restartServer' })
    this.props.onModalShow(!this.props.show)
    if (confirmed) {
      this.vpsService.restartVpsServer(this.props.vpsDetails.id).then(response => {
        this.vpsService.findVpsServer(this.props.vpsDetails.id).then(server => {
          this.props.setVpsDetails(server)
        }).catch(console.log)
      }).catch(console.log)
    }
  }

  stopServer (confirmed: boolean): void {
    this.setState({ currentAction: 'stopServer' })
    this.props.onModalShow(!this.props.show)
    if (confirmed) {
      this.vpsService.stopVpsServer(this.props.vpsDetails.id).then(response => {
        this.vpsService.findVpsServer(this.props.vpsDetails.id).then(server => {
          this.props.setVpsDetails(server)
        }).catch(console.log)
      }).catch(console.log)
    }
  }

  deleteServer (confirmed: boolean): void {
    this.setState({ currentAction: 'deleteServer' })
    this.props.onModalShow(!this.props.show)
    if (confirmed) {
      this.vpsService.deleteVpsServer(this.props.vpsDetails.id).then(response => {
        if (response.status === 200) {
          this.props.setVpsDetails(null)
        }
      }).catch(console.log)
      this.setState({ isConfirmed: false })
    }
  }

  setVpsPeriod (period: string): void {
    this.props.setPeriod(period)
  }

  isConfirmed (): void {
    switch (this.state.currentAction) {
      case 'deleteServer':
        this.deleteServer(true)
        break
      case 'restartServer':
        this.restartServer(true)
        break
      case 'stopServer':
        this.stopServer(true)
        break
      default:
        break
    }
  }

  copyToClipboard (value: string): void {
    navigator.clipboard.writeText(value).catch(console.log)
  }

  downloadConfig (): void {
    const ip = this.props.vpsDetails.public_net.ipv4?.ip
    const name = this.props.vpsDetails.name.trim()
      .replaceAll(' ', '-')
      .replace(/[^a-z0-9-]/gi, '')
      .replace(/-+/gi, '-')
      .toLowerCase()
    const connectionFileString = this.vpsService.generateXmlClientConnectionFile(ip)
    const blob = new Blob([connectionFileString], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = `${name}.nxs`
    link.href = url
    link.click()
  }

  calculateCurrentPrice (): number {
    const creationDate = new Date(this.props.vpsDetails.created).getTime()
    const currentTimestamp = new Date().getTime()
    const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime()
    let currentPrice = 0
    let hoursSinceCreated: number

    if (creationDate > firstDayOfMonth) {
      hoursSinceCreated = (currentTimestamp - creationDate) / 1000 / 60 / 60
    } else {
      hoursSinceCreated = (currentTimestamp - firstDayOfMonth) / 1000 / 60 / 60
    }

    if(this.props.vpsDetails.server_type === null) {
      return currentPrice
    }

    this.props.vpsDetails.server_type.prices.forEach(price => {
      if (price.location === this.props.vpsDetails.datacenter.location.name) {
        currentPrice += (parseFloat(price.price_hourly.net) * hoursSinceCreated) * 5
      }
    })

    currentPrice = Math.floor(currentPrice * 10000) / 10000

    return currentPrice
  }

  calculateMonthlyPrice (): number {
    let monthlyPrice = 0

    this.props.vpsDetails.server_type.prices.forEach(price => {
      if (price.location === this.props.vpsDetails.datacenter.location.name) {
        monthlyPrice += parseFloat(price.price_monthly.net) * 5
      }
    })

    return monthlyPrice
  }

  editLabels (): void {
    this.vpsService.updateVpsServer(this.props.vpsDetails.id, { hostName: this.props.vpsDetails.name, labels: this.state.labels }).then((response: ApiResponse) => {
      if (response.status === 200) {
        this.setState({ editLabels: false })
        this.refreshDetails()
      }
    }).catch(console.log)
  }

  onChangeLabel (e: ChangeEvent<HTMLInputElement>): void {
    this.setState({ labels: { ...this.props.vpsDetails.labels, [e.target.name]: e.target.value } })

    if (e.target.name.toLowerCase().includes('date')) {
      this.setState({ labels: { ...this.props.vpsDetails.labels, [e.target.name]: new Date(e.target.value).getTime().toString() } })
    }
  }

  renderLabels (): JSX.Element {
    const HIDDEN_LABELS: string[] = ['Client', 'ManagedBy', 'LicenceEndDate', 'ClientName', 'installation', 'LinkedInAccount']

    if (this.state.editLabels) {
      return (
        <VpsLabelForm serverType="vps" toggleForm={() => { this.setState({ editLabels: !this.state.editLabels }) }} title={'Edit server:'} update={true}/>
      )
    }
    return (
      <div style={{ width: '18em' }}>
          <div className='m-2 d-flex justify-content-between'>
              <h2>Labels: </h2>
              <button onClick={() => { this.setState({ editLabels: !this.state.editLabels }) }} className="btn btn-info" style={{ color: 'white' }}>Edit</button>
          </div>
          <div className='m-2'><p><strong>Client Name:</strong> {this.props.vpsDetails.labels.ClientName ?? 'not available'}</p></div>
          <div className='m-2'><p><strong>LinkedIn Account:</strong> {this.props.vpsDetails.labels.LinkedInAccount ?? 'not available'}</p></div>
          <div className='m-2'><p><strong>Licence End Date:</strong> {new Date(parseInt(this.props.vpsDetails.labels.LicenceEndDate)).toDateString() ?? 'not available'}</p></div>
          {
              Object.keys(this.props.vpsDetails.labels).map((key, i) => {
                return HIDDEN_LABELS.includes(key)
                  ? null
                  : <div key={i} className='m-2'><p><strong>{key}: </strong> {this.props.vpsDetails.labels[key] ?? 'not available'}</p></div>
              })
          }
      </div>
    )
  }

  render (): JSX.Element {
    if (this.props.vpsDetails === null) {
      return <Navigate to={'/vps/generic/overview'} />
    }
    const server = this.props.vpsDetails

    return (
            <>
                <ConfirmationModal action={this.state.currentAction} onConfirm={this.isConfirmed} />
                <div className="col-lg-12">
                    <div className="card">
                        {
                            this.state.editLabels
                              ? this.renderLabels()
                              : <div className="header d-flex justify-content-between">
                                    <div>
                                        <h2>
                                            {server.name}
                                            <small>
                                                Status: {server.status}
                                            </small>
                                            <br />
                                            <small>current price: {CurrencyService.format(this.calculateCurrentPrice())}</small>
                                            <small>monthly price: {CurrencyService.format(this.calculateMonthlyPrice())}</small>
                                        </h2>

                                    </div>
                                  <div>
                                    <div className="m-2"><p><strong>RAM:</strong> {server.server_type.memory}GB</p></div>
                                    <div className="m-2"><p><strong>Disk:</strong> {server.server_type.disk}GB</p></div>
                                    <div className="m-2"><p><strong>VCPU:</strong> {server.server_type.cores}</p></div>
                                    <div className="m-2"><p><CopyClipboard title={'IPv4'}
                                                                           value={server.public_net.ipv4?.ip}/></p></div>
                                    <div className="m-2"><p><CopyClipboard title={'IPv6'}
                                                                           value={server.public_net.ipv6?.ip}/></p></div>
                                    <div className="m-2"><p><strong>Created on:</strong> {server.created}</p></div>
                                  </div>
                                  <div>
                                    <div className="m-2"><h2>Actions: </h2></div>
                                    <div>
                                      <button onClick={this.startServer} className="btn btn-primary m-2"
                                              disabled={server.status !== 'off'}>Start
                                      </button></div>
                                        <div><button onClick={() => { this.restartServer(false) }} className="btn btn-success m-2" disabled={server.status !== 'running'}>Restart</button></div>
                                        <div><button onClick={() => { this.stopServer(false) }} className="btn btn-danger m-2" disabled={server.status !== 'running'}>Stop</button></div>
                                        <div><button onClick={() => { this.deleteServer(false) }} className='btn btn-danger m-2'>Delete Server</button></div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                    <div className="btn-group me-2 m-2">
                        <button onClick={() => { this.setVpsPeriod('15m') }} type="button" className="btn btn-sm btn-outline-secondary">15m</button>
                        <button onClick={() => { this.setVpsPeriod('3h') }} type="button" className="btn btn-sm btn-outline-secondary">3h</button>
                        <button onClick={() => { this.setVpsPeriod('24h') }} type="button" className="btn btn-sm btn-outline-secondary">24h</button>
                        <button onClick={this.refreshDetails} className="btn btn-sm btn-outline-secondary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                                <path
                                    d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <VpsChart id={server.id} period={this.props.period} />
            </>
    )
  }
}

// @ts-expect-error
const mapStateToProps = ({ vpsReducer, modalReducer }): { vpsDetails: VpsData | null, period: string, show: boolean } => ({
  vpsDetails: vpsReducer.vpsDetails,
  period: vpsReducer.period,
  show: modalReducer.show
})

export default connect(mapStateToProps, {
  setVpsDetails,
  setPeriod,
  onModalShow
// @ts-expect-error
})(VpsDetails)
