import React from 'react'
import NavBarTopLogo from './NavBarTopLogo'
import NavBarTopActionButtons from './NavBarTopActionButtons'
import NavBarTopMenuButton from './NavBarTopMenuButton'

class NavBarTop extends React.Component {
  render (): JSX.Element {
    return (
        <nav className="navbar navbar-fixed-top">
          <div className="container-fluid">
            <NavBarTopMenuButton/>
            <NavBarTopLogo/>
            <NavBarTopActionButtons/>
          </div>
        </nav>
    )
  }
}

export default NavBarTop
