import ApiService, { ApiResponse } from './ApiService'
import CustomerService from './CustomerService'
import CustomerData from '../Models/CustomerData'
import InvoiceData from '../Models/InvoiceData'

class InvoiceService {
  private readonly api: ApiService
  private readonly customerService: CustomerService

  constructor () {
    this.api = new ApiService()
    this.customerService = new CustomerService()
  }

  getInvoicesFromCustomer (): Promise<InvoiceData[]> {
    return new Promise((resolve, reject) => {
      this.customerService.getActiveCustomer().then((activeCustomer: CustomerData | null) => {
        if (activeCustomer === null) {
          return reject
        }

        const url = '/api/v1/' + activeCustomer.uuid + '/invoice'

        this.api.apiCall(url).then((response: ApiResponse) => {
          return resolve(response.data)
        }).catch(reject)
      }).catch(reject)
    })
  }

  filterRecentInvoices (invoices: InvoiceData[]): Promise<InvoiceData[]> {
    return new Promise((resolve, reject) => {
      const filteredInvoices: InvoiceData[] = []
      const now: number = Date.now()
      const threeMonthsAgo: number = (now - (((60000 * 60) * (24)) * (30)) * 3) / 1000
      invoices.forEach((invoice) => {
        if (invoice.createdOn > threeMonthsAgo) {
          filteredInvoices.push(invoice)
        }
      })

      return resolve(filteredInvoices)
    })
  }
}

export default InvoiceService
