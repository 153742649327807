import React from 'react'
import {connect} from 'react-redux'
import InvoiceData, {InvoiceLine} from '../../Models/InvoiceData'
import CustomerService from '../../Services/CustomerService'
import CustomerData from '../../Models/CustomerData'
import OrderData from '../../Models/OrderData'
import {Navigate} from 'react-router-dom'
import Loading from '../Screens/Loading'

interface PropTypes {
    invoiceDetails: InvoiceData | null
}

interface StateTypes {
    customerData: CustomerData | null
    orderData: OrderData | null
}

class InvoiceDetails extends React.Component<PropTypes, StateTypes> {
    private readonly customerService: CustomerService

    constructor(props: PropTypes) {
        super(props)

        this.state = {
            customerData: null,
            orderData: null
        }

        this.customerService = new CustomerService()
    }

    componentDidMount(): void {
        const {invoiceDetails} = this.props
        this.customerService.getActiveCustomer().then((customerData: CustomerData | null) => {
            this.setState({customerData})
        }).catch(console.log)

        if (invoiceDetails == null) {
            return
        }
    }

    render(): JSX.Element {
        const {customerData} = this.state
        const {invoiceDetails} = this.props

        if (invoiceDetails === null) {
            return <Navigate to={'/invoices/recent'}/>
        }

        if (customerData === null) {
            return <Loading/>
        }

        return (
            <div>
                <div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="body">
                                        <h3>
                                            Invoice Details :{' '}
                                            <strong className="text-primary">#A0089</strong>
                                        </h3>
                                        <div className="tab-content">
                                            <div className={'tab-pane active'}>
                                                <div className="row clearfix">
                                                    <div className="col-md-6 col-sm-6">
                                                        <address>
                                                            <strong>{customerData.companyName}</strong>
                                                            <br/> {customerData.street} {customerData.streetNumber} {customerData.streetNumberAddition ?? null}
                                                            <br/> {customerData.city}, {customerData.postalCode}
                                                        </address>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 text-right">
                                                        <p className="m-b-0">
                                                            <strong>
                                                                Date: </strong> {new Date(invoiceDetails.createdOn * 1000).toISOString().split('T')[0]}
                                                        </p>
                                                        <p className="m-b-0">
                                                            <strong>Invoice Status: </strong>
                                                            <span
                                                                className="badge badge-warning m-b-0">{invoiceDetails.invoiceStatus}</span>
                                                        </p>
                                                        <p>
                                                            <strong>Invoice ID: </strong> {invoiceDetails.uuid}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover">
                                                                <thead className="thead-dark">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Item</th>
                                                                    <th className="hidden-sm-down">
                                                                        Description
                                                                    </th>
                                                                    <th>Quantity</th>
                                                                    <th className="hidden-sm-down">
                                                                        Unit Cost
                                                                    </th>
                                                                    <th>Total</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    invoiceDetails.invoiceLines.length === 0
                                                                        ? <h3>Data not available</h3>
                                                                        : invoiceDetails.invoiceLines.map((invoiceLine: InvoiceLine, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td></td>
                                                                                    <td>{invoiceLine.name}</td>
                                                                                    <td className="hidden-sm-down">
                                                                                        {invoiceLine.description}
                                                                                    </td>
                                                                                    <td>{invoiceLine.quantity}</td>
                                                                                    <td className="hidden-sm-down">{invoiceLine.price}</td>
                                                                                    <td>{invoiceLine.price * invoiceLine.quantity}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row clearfix">
                                                    <div className="col-md-6"/>
                                                    <div className="col-md-6 text-right">
                                                        <p className="m-b-0">
                                                            <b>Sub-total:</b> {invoiceDetails.totalAmount}
                                                        </p>
                                                        <p className="m-b-0">{}</p>
                                                        <p className="m-b-0">VAT: {invoiceDetails.vat}%</p>
                                                        <h3 className="m-b-0 m-t-10">{invoiceDetails.currency} {invoiceDetails.totalAmountInclVat}</h3>
                                                    </div>
                                                    <div className="hidden-print col-md-12 text-right">
                                                        <hr/>
                                                        <button className="btn btn-outline-secondary mr-1">
                                                            <i className="icon-printer"></i>
                                                        </button>
                                                        <button className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// @ts-expect-error
const mapStateToProps = ({invoiceReducer}): { invoiceDetails: InvoiceData | null } => ({
    invoiceDetails: invoiceReducer.invoiceDetails
})

export default connect(mapStateToProps, {})(InvoiceDetails)
