import React, { ChangeEvent } from 'react'
import AccountService from '../../Services/AccountService'
import UserData from '../../Models/UserData'
import AuthService from '../../Services/AuthService'
import Alert from 'react-bootstrap/Alert'

interface StateTypes {
  currentUser: UserData | null
  newUsername: string | null
  newPassword: string | null
  oldPassword: string | null
  success: boolean | null
  message: string
}
class EditAccount extends React.Component<any, StateTypes> {
  private readonly accountService: AccountService
  private readonly authService: AuthService
  constructor (props: any) {
    super(props)

    this.state = {
      currentUser: null,
      newUsername: null,
      newPassword: null,
      oldPassword: null,
      success: null,
      message: ''
    }

    this.accountService = new AccountService()
    this.authService = new AuthService()

    this.updateProfile = this.updateProfile.bind(this)
    this.changeProfileData = this.changeProfileData.bind(this)
    this.setNewUsername = this.setNewUsername.bind(this)
    this.changeUsername = this.changeUsername.bind(this)
    this.setNewPassword = this.setNewPassword.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.setOldPassword = this.setOldPassword.bind(this)
  }

  componentDidMount (): void {
    try {
      const currentUser = AccountService.getCurrentUserFromLocalStorage();
      this.setState({ currentUser })
    } catch (e) {
      console.error('Could not get the current user from local storage')
    }
  }

  setNewUsername (e: ChangeEvent<HTMLInputElement>): void {
    this.setState({ newUsername: e.target.value })
  }

  changeUsername (): void {
    if (this.state.currentUser === null || this.state.oldPassword === null || this.state.newUsername === null) {
      return
    }
    const uuid = this.state.currentUser.uuid
    const newUsername = this.state.newUsername

    // @todo implement
    console.error('IMPLEMENT change username flow')
  }

  setNewPassword (e: ChangeEvent<HTMLInputElement>): void {
    this.setState({ newPassword: e.target.value })
  }

  changePassword (): void {
    if (this.state.currentUser === null || this.state.oldPassword === null || this.state.newPassword === null) {
      return
    }

    const uuid = this.state.currentUser.uuid
    const newPassword = this.state.newPassword

    // @todo implement
    console.error('IMPLEMENT change password flow')
  }

  setOldPassword (e: ChangeEvent<HTMLInputElement>): void {
    this.setState({ oldPassword: e.target.value })
  }

  updateProfile (): null | undefined {
    if (this.state.currentUser === null) {
      return null
    }

    this.accountService.updateUser(this.state.currentUser).then((response: boolean) => {
      if (response) {
        console.log('Account successfully updated')
      } else {
        console.log('Account update failed')
      }
    }).catch(console.log)
  }

  changeProfileData (e: ChangeEvent<HTMLInputElement>): void {
    // @ts-expect-error
    this.setState({ currentUser: { ...this.state.currentUser, [e.target.name]: e.target.value } })
    console.log(this.state)
  }

  render (): JSX.Element {
    const { currentUser, success, message } = this.state

    return (
            <>
                {
                    success !== null
                      ? <Alert variant={success ? 'success' : 'danger'}>
                            {message}
                        </Alert>
                      : null
                }

                <div className='card'>
                    <div className="body">
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-12">
                                <h6>Account Data</h6>

                                <div className="form-group">
                                    <div className="input-group">
                                        <span className="input-group-text">Firstname</span>
                                        <input type="text" className="form-control"
                                               name='firstName'
                                               placeholder="Firstname..."
                                               onChange={this.changeProfileData}
                                               defaultValue={currentUser?.firstName}
                                        />

                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="input-group">
                                        <span className="input-group-text">Insertion</span>
                                        <input type="text" className="form-control"
                                               name='insertion'
                                               placeholder="Insertion..."
                                               onChange={this.changeProfileData}
                                               defaultValue={currentUser?.insertion ?? ''}
                                        />

                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <span className="input-group-text">
                                    Lastname
                                </span>
                                        <input type="text" className="form-control"
                                               name='lastName'
                                               placeholder="Lastname..."
                                               onChange={this.changeProfileData}
                                               defaultValue={currentUser?.lastName}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button onClick={this.updateProfile} className="btn btn-primary" type="button">
                            Update Profile
                        </button>{' '}
                        &nbsp;&nbsp;
                    </div>
                </div>
                <div className='card'>
                    <div className="body">
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-12">
                                <h6>Change Username</h6>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        placeholder="New Username"
                                        type="text"
                                        onChange={this.setNewUsername}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        placeholder="Your Password"
                                        type="password"
                                        onChange={this.setOldPassword}
                                    />
                                </div>
                            </div>
                        </div>
                        <button onClick={this.changeUsername} className="btn btn-primary" type="button">
                            Update Username
                        </button>
                        &nbsp;&nbsp;
                    </div>
                </div>
                <div className='card'>
                    <div className="body">
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-12">
                                <h6>Change Password</h6>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        placeholder="Current Password"
                                        type="password"
                                        defaultValue={''}
                                        onChange={this.setNewPassword}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        placeholder="New Password"
                                        type="password"
                                        onChange={this.setOldPassword}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        placeholder="Confirm New Password"
                                        type="password"
                                    />
                                </div>
                            </div>
                        </div>
                        <button onClick={this.changePassword} className="btn btn-primary" type="button">
                            Update Password
                        </button>{' '}
                        &nbsp;&nbsp;
                    </div>
                </div>
            </>
    )
  }
}

export default EditAccount
