import ApiService, { ApiResponse } from './ApiService'
import DnsRecordData from '../Models/Dns/DnsRecordData'
import DnsZoneData from '../Models/DnsZoneData'

class DnsService {
  private readonly api: ApiService

  constructor () {
    this.api = new ApiService()
  }

  createDnsRecord (dnsRecord: DnsRecordData): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      this.api.apiCall('/api/v1/dns/record', 'POST', JSON.stringify(dnsRecord)).then(response => {
        return resolve(response)
      })
    })
  }

  getDnsZoneByDomain (domainUuid: string): Promise<DnsZoneData> {
    return new Promise((resolve, reject) => {
      this.api.apiCall(`/api/v1/dns/zone/${domainUuid}/domain`).then(response => {
        return resolve(response.data)
      }).catch(reject)
    })
  }
}

export default DnsService
