import React from 'react'
import { connect } from 'react-redux'
import { onPressSideMenuToggle } from '../../../actions'

interface PropTypes {
  onPressSideMenuToggle: () => {}
}

const NavBarTopMenuButton = (props: PropTypes): JSX.Element => {
  return (
      <div className="navbar-btn">
        <button
            className="btn-toggle-offcanvas"
            onClick={() => {
              props.onPressSideMenuToggle()
            }}
        >
          <i className="lnr lnr-menu fa fa-bars"></i>
        </button>
      </div>
  )
}

// @ts-expect-error
const mapStateToProps = ({ loginReducer }): { isLoggedIn: boolean } => ({
  isLoggedIn: loginReducer.isLoggedIn
})

export default connect(mapStateToProps, {
  onPressSideMenuToggle
// @ts-expect-error
})(NavBarTopMenuButton)
