import React from 'react'
import CustomerService from '../../Services/CustomerService'
import { connect } from 'react-redux'
import CustomerData from '../../Models/CustomerData'
import QuoteData from '../../Models/QuoteData'
import ProductData from '../../Models/ProductData'

interface PropTypes {
  quoteDetails: QuoteData | null
}

interface StateTypes {
  customerData: CustomerData | null
}
class QuoteDetails extends React.Component<PropTypes, StateTypes> {
  private readonly customerService: CustomerService
  constructor (props: PropTypes) {
    super(props)

    this.state = {
      customerData: null
    }

    this.customerService = new CustomerService()
  }

  componentDidMount (): void {
    this.customerService.getActiveCustomer().then((customerData: CustomerData | null) => {
      this.setState({ customerData })
    }).catch(console.log)
  }

  render (): JSX.Element {
    const { customerData } = this.state
    const { quoteDetails } = this.props

    return (
      quoteDetails?.products === null
        ? this.renderQuoteDetails(quoteDetails, customerData)
        : <div>
                    <div>
                        <div className="container-fluid">
                            <div className="row clearfix">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <h2 style={{ margin: '30px' }}>No products assigned to quote</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
  }

  renderQuoteDetails (quoteDetails: QuoteData | null, customerData: CustomerData | null): JSX.Element {
    return (
            <div>
                <div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="body">
                                        <h3>
                                            Quote Details :{' '}
                                            <strong className="text-primary">{quoteDetails?.uuid}</strong>
                                        </h3>
                                        <div className="tab-content">
                                            <div className={'tab-pane active'}>
                                                <div className="row clearfix">
                                                    <div className="col-md-6 col-sm-6">
                                                        <address>
                                                            <strong>{customerData?.companyName}</strong>
                                                            <br/> {customerData?.street} {customerData?.streetNumber} {customerData?.streetNumberAddition ?? null}
                                                            <br/> {customerData?.city}, {customerData?.postalCode}
                                                        </address>
                                                    </div>
                                                </div>
                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover">
                                                                <thead className="thead-dark">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Item</th>
                                                                    <th className="hidden-sm-down">
                                                                        Description
                                                                    </th>
                                                                    <th>Quantity</th>
                                                                    <th className="hidden-sm-down">
                                                                        Unit Cost
                                                                    </th>
                                                                    <th>Total</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    quoteDetails?.products.map((product: ProductData, index) => {
                                                                      return (
                                                                            <tr key={index}>
                                                                                <td>1</td>
                                                                                <td>{product.productName}</td>
                                                                                <td className="hidden-sm-down">
                                                                                    {product.description}
                                                                                </td>
                                                                                <td>{product.quantity}</td>
                                                                                <td className="hidden-sm-down">{product.priceExcl}</td>
                                                                                <td>{product.priceExcl * product.quantity}</td>
                                                                            </tr>
                                                                      )
                                                                    })
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row clearfix">
                                                    <div className="col-md-6"/>
                                                    <div className="col-md-6 text-right">
                                                        <p className="m-b-0">
                                                            <b>Sub-total:</b> {quoteDetails?.totalAmount}
                                                        </p>
                                                        <p className="m-b-0">{}</p>
                                                        <p className="m-b-0">VAT: {quoteDetails?.vatPercentage}%</p>
                                                        <h3 className="m-b-0 m-t-10">{quoteDetails?.currency} {quoteDetails?.subTotal}</h3>
                                                    </div>
                                                    <div className="hidden-print col-md-12 text-right">
                                                        <hr/>
                                                        <button className="btn btn-outline-secondary mr-1">
                                                            <i className="icon-printer"></i>
                                                        </button>
                                                        <button className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
  }
}

// @ts-expect-error
const mapStateToProps = ({ quoteReducer }): { quoteDetails: QuoteData | null } => ({
  quoteDetails: quoteReducer.quoteDetails
})

export default connect(mapStateToProps, {
})(QuoteDetails)
