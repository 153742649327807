import React from 'react'
import GeneralModal from './GeneralModal'

interface PropTypes {
  onConfirm: () => void
  action: string | null
}

class ConfirmationModal extends React.Component<PropTypes, any> {
  render (): JSX.Element {
    return (
            <GeneralModal
                title={'Confirmation'}
                closeButtonText={'Cancel'}
                primaryButtonText={'Confirm'}
                bodyText={`Are you sure you want to ${this.props.action}?`}
                onSubmit={this.props.onConfirm}
            />
    )
  }
}

export default ConfirmationModal
