import SubscriptionData from '../Models/SubscriptionData'
import ApiService, { ApiResponse } from './ApiService'

class SubscriptionService {
  private readonly api: ApiService

  constructor () {
    this.api = new ApiService()
  }

  getSubscriptions (): Promise<SubscriptionData[]> {
    return new Promise((resolve, reject) => {
      this.api.apiCall('/api/v1/subscriptions').then((response: ApiResponse) => {
        return resolve(response.data)
      }).catch(reject)
    })
  }
}

export default SubscriptionService
