import React from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import { onActiveCustomerChange } from '../../../actions'
import CustomerData from '../../../Models/CustomerData'
import CustomerService from '../../../Services/CustomerService'

interface PropTypes {
  fetchedFromLocalStorage: boolean
  activeCustomer: CustomerData | null
  customers: CustomerData[] | null
  onActiveCustomerChange: (activeCustomer: CustomerData) => {}
}

class NavBarSideCustomerDetails extends React.Component<PropTypes, {}> {
  constructor (props: PropTypes) {
    super(props)

    this.getActiveCustomer = this.getActiveCustomer.bind(this)
    this.setActiveCustomer = this.setActiveCustomer.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount (): void {
    const { fetchedFromLocalStorage } = this.props
    const customerService = new CustomerService()

    if (!fetchedFromLocalStorage) {
      customerService.getActiveCustomer().then(customer => {
        if (customer === null) return null

        return this.setActiveCustomer(customer)
      }).catch(console.log)
    }
  }

  getActiveCustomer (): CustomerData | null {
    const { activeCustomer, fetchedFromLocalStorage } = this.props
    const customerService = new CustomerService()

    if (!fetchedFromLocalStorage) {
      return null
    }

    if (activeCustomer === null) {
      customerService.getActiveCustomer().then(customer => {
        if (customer === null) return null

        return this.setActiveCustomer(customer)
      }).catch(console.log)

      return null
    }

    return activeCustomer
  }

  setActiveCustomer (customer: CustomerData): void {
    const customerService = new CustomerService()
    customerService.storeActiveCustomer(customer)
    this.props.onActiveCustomerChange(customer)
  }

  onClick (customer: CustomerData): void {
    this.setActiveCustomer(customer)
  }

  oneCustomer (customers: Array<CustomerData | null>): JSX.Element | null {
    const customerService = new CustomerService()
    const customer: CustomerData | null | undefined = customers.shift()

    if (customer === null || customer === undefined) {
      return null
    }

    customerService.storeActiveCustomer(customer)
    return this.singleCustomer(customer?.companyName)
  }

  loadingCustomers (): JSX.Element {
    return this.singleCustomer('loading')
  }

  noCustomers (): JSX.Element {
    return this.singleCustomer('-')
  }

  multipleCustomers (customers: CustomerData[]): JSX.Element {
    const activeCustomer: CustomerData | null = this.getActiveCustomer()

    return (
            <Dropdown>

                <span>Active customer,</span>
                <Dropdown.Toggle
                    variant="none"
                    as="a"
                    id="dropdown-basic"
                    className="user-name"
                >
                    <strong>{ (activeCustomer !== null ? activeCustomer.companyName : customers[0].companyName) }</strong>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-right account">
                    <Dropdown.Item>
                        { activeCustomer !== null ? activeCustomer.companyName : customers[0].companyName }
                        <li className="divider"></li>
                    </Dropdown.Item>
                    { customers.map((customer: CustomerData, i: number) =>
                        <Dropdown.Item key={i} onClick={() => this.onClick(customer)}>
                            {customer !== activeCustomer ? customer.companyName : ''}
                        </Dropdown.Item>
                    ) }

                </Dropdown.Menu>
            </Dropdown>
    )
  }

  singleCustomer (value: string): JSX.Element {
    return (
            <>
                <span>Active customer,</span>
                <br />
                <strong>{value}</strong>
            </>
    )
  }

  render (): JSX.Element | null {
    const activeCustomer = this.props.activeCustomer === null ? null : [this.props.activeCustomer]
    const customers: CustomerData[] | null = this.props.customers === null ? activeCustomer : this.props.customers

    if (customers === null) {
      return this.loadingCustomers()
    }
    if (customers.length > 1) {
      return this.multipleCustomers(customers)
    }
    if (customers?.length === 1) {
      return this.oneCustomer(customers)
    }

    return this.noCustomers()
  }
}

// @ts-expect-error
const mapStateToProps = ({ customerReducer }): { fetchedFromLocalStorage: boolean, activeCustomer: CustomerData | null } => ({
  fetchedFromLocalStorage: customerReducer.fetchedFromLocalStorage,
  activeCustomer: customerReducer.activeCustomer
})

export default connect(mapStateToProps, {
  onActiveCustomerChange
// @ts-expect-error
})(NavBarSideCustomerDetails)
