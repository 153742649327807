import React from 'react'
import Layout from '../../components/public/Layout'

class Page503 extends React.Component {
  renderTitle (): JSX.Element {
    return (
        <h3>
          <span className="clearfix title">
            <span className="number left">Error 503</span>{' '}
            <span className="text"><br />Please try after some time</span>
          </span>
        </h3>
    )
  }

  renderBody (): JSX.Element {
    return (
      <>
        <p>The site is temporary unavailable, we are getting it up in few minutes.</p>
        <div className="margin-top-30">
          <a className="btn btn-primary" href="/dashboard">
            <i className="fa fa-home"></i>&nbsp;<span >Home</span>
          </a>
        </div>
      </>

    )
  }

  render (): JSX.Element {
    return (
        <Layout
            title={this.renderTitle()}
            forgotPassword={false}
            register={false}
            login={false}
            forgotPasswordLogin={false}
            loading={false}
            children={this.renderBody()}
        />
    )
  }
}

export default Page503
