import { useLogto, type IdTokenClaims } from '@logto/react';
import React, { useEffect, useState } from 'react';
import UnAuthorized from '../../routes/unauthorized';
import { BrowserRouter } from 'react-router-dom';
import TokenService from '../../Services/TokenService';

type PropTypes = {
  children: React.ReactElement;
}

const AuthCheckProvider = (props: PropTypes) => {
  const { isAuthenticated, getIdTokenClaims, getAccessToken, isLoading } = useLogto();
  const [user, setUser] = useState<IdTokenClaims>();

  useEffect(() => {
    const fetchUserClaims = async () => {
      if (isAuthenticated) {
        const claims = await getIdTokenClaims();
        setUser(claims);

        const accessToken = await getAccessToken();
        if (typeof accessToken === 'string') {
          TokenService.storeAccessTokenCookie(accessToken, 360);
        }
      }
    };

    fetchUserClaims();
  }, [getIdTokenClaims, getAccessToken, isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ?
      <>
        {props.children}
      </>
      :
      <BrowserRouter><UnAuthorized /></BrowserRouter>;
}

export default AuthCheckProvider;
