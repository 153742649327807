import React from 'react'
import { Dropdown } from 'react-bootstrap'

class BlankPage extends React.Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }

  render () {
    return (
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="card planned_task">
              <div className="header">
                <h2>Page not found</h2>
              </div>
              <div className="body">
                <p>The page you are looking for is not found or you do not have access to this page.</p>
              </div>

            </div>
          </div>
        </div>
    )
  }
}


export default BlankPage;
