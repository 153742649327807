import React from 'react';
import AuthService from '../../Services/AuthService';
import { LogtoConfig, LogtoProvider } from '@logto/react';
import TokenService from '../../Services/TokenService';
import AuthCallbackProvider from './AuthCallbackProvider';


interface PropTypes {
  children: React.ReactElement;
}

class AuthConfigProvider extends React.Component<PropTypes, { } > {
  private authService: AuthService | undefined;

  constructor(props: PropTypes | Readonly<PropTypes>) {
    super(props);

    this.authService = new AuthService();
  }


  render(): JSX.Element | null {

    const config: LogtoConfig = {
      endpoint: this.authService?.getAuthConfig().endpoint ?? '',
      appId: this.authService?.getAuthConfig().clientId ?? '',
    };

    if (window.location.search.includes('code')) {
      TokenService.removeAccessTokenCookie();
    }

    return (
        <LogtoProvider config={config}>
          { window.location.search.includes('code') ?
              <AuthCallbackProvider />
              :
              this.props.children
          }
        </LogtoProvider>
    );
  }
}

export default AuthConfigProvider;
