import {
  SET_QUOTE_DETAILS
} from '../actions'
import QuoteData from '../Models/QuoteData'

interface QuoteReducerState {
  quoteDetails: QuoteData | null
}

const initialState: QuoteReducerState = {
  quoteDetails: null
}

interface Action {
  type: string
  payload: QuoteData
}

const quoteReducer = (state = initialState, action: Action): QuoteReducerState => {
  switch (action.type) {
    case SET_QUOTE_DETAILS: {
      return {
        ...state,
        quoteDetails: action.payload
      }
    }

    default:
      return state
  }
}

export default quoteReducer
