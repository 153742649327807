import { SET_DISABLE_CREATE_BUTTON, SET_HIDE_CLOSE_BUTTON, SET_MODEL_SHOW } from '../actions'

interface ModalReducerState {
  show: boolean
  isHidden: boolean
  isDisabled: boolean
}

const initialState: ModalReducerState = {
  show: false,
  isHidden: false,
  isDisabled: false
}

interface Action {
  type: string
  payload: boolean
}

const modalReducer = (state: ModalReducerState = initialState, action: Action): ModalReducerState => {
  switch (action.type) {
    case SET_MODEL_SHOW: {
      return {
        ...state,
        show: action.payload
      }
    }
    case SET_HIDE_CLOSE_BUTTON: {
      return {
        ...state,
        isHidden: action.payload
      }
    }
    case SET_DISABLE_CREATE_BUTTON: {
      return {
        ...state,
        isDisabled: action.payload
      }
    }

    default:
      return state
  }
}

export default modalReducer
