import CustomerData from '../Models/CustomerData';
import CustomerFormData from '../Models/CustomerFormData';
import ApiService, { ApiResponse } from './ApiService';
import AccountService from './AccountService';

class CustomerService {
  static localStorageCustomerKey = 'activeCustomer';
  private readonly api: ApiService;


  constructor() {
    this.api = new ApiService();
  }

  storeActiveCustomer(customer: CustomerData): CustomerData {
    localStorage.setItem(CustomerService.localStorageCustomerKey, customer.uuid);
    return customer;
  }

  getActiveCustomer(): Promise<CustomerData | null> {
    return new Promise((resolve, reject) => {
      const activeCustomerUuid: string | null = CustomerService.getActiveCustomerUuid();

      if (activeCustomerUuid !== null) {
        this.getCustomerByUuid(activeCustomerUuid).then((activeCustomer: CustomerData) => {
          return resolve(activeCustomer);
        }).catch(reject);
      } else {
        throw new Error('No active customer found');
      }
    });
  }

  static getActiveCustomerUuid(): string | null {
    return localStorage.getItem(CustomerService.localStorageCustomerKey);
  }

  createCustomer(customer: CustomerFormData): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        const user = AccountService.getCurrentUserFromLocalStorage();
        customer.accounts = user.uuid;
      } catch (e) {
        console.error('Could not get the current user from local storage')
        reject(e);
        return;
      }

      this.api.apiCall('/api/v1/customer', 'POST', JSON.stringify(customer))
          .then((response: ApiResponse) => {
            if (response.status !== 200) {
              return reject(response.meta?.error?.message);
            }
            return resolve(true);
          }).catch(reject);
    });
  }

  getCustomerByUuid(uuid: string): Promise<CustomerData> {
    return new Promise((resolve, reject) => {
      this.api.apiCall(`/api/v1/customer/${uuid}`).then((response: ApiResponse) => {
        if (response.status !== 200) {
          return reject(response.meta?.error?.message);
        }
        return resolve(response.data);
      }).catch(reject);
    });
  }
}

export default CustomerService;
