import React, { ChangeEvent } from 'react'
import Alert from 'react-bootstrap/Alert'
import { Button } from 'react-bootstrap'
import VpsService from '../../../Services/VpsService'
import { connect } from 'react-redux'
import { setVpsDetails, setLinkedHelperVpsServers } from '../../../actions'
import VpsData from '../../../Models/Vps/VpsData'
import CustomerService from '../../../Services/CustomerService'

interface PropTypes {
  toggleForm: () => void
  vpsDetails: VpsData | null
  setVpsDetails: (val: VpsData | null) => {}
  vpsServers: VpsData[] | null
  setLinkedHelperVpsServers: (val: VpsData[] | null) => {}
  title: string
  update: boolean
  serverType: 'vps' | 'wordpress' | 'linked-helper'
}

interface StateTypes {
  createRequest: boolean
  hostname: string
  labels: object
  newFields: Array<{ key: string, value: string }>
  errorMessage: string | null
}

class VpsLabelForm extends React.Component<PropTypes, StateTypes> {
  private readonly vpsService: VpsService
  private readonly customerService: CustomerService
  constructor (props: PropTypes) {
    super(props)

    this.state = {
      createRequest: false,
      hostname: '',
      labels: {},
      newFields: [],
      errorMessage: null
    }

    this.vpsService = new VpsService()
    this.customerService = new CustomerService()

    this.submitServer = this.submitServer.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.setLabel = this.setLabel.bind(this)
    this.addLabelField = this.addLabelField.bind(this)
    this.validateLabels = this.validateLabels.bind(this)
  }

  componentDidMount (): void {
    const HIDDEN_LABELS: string[] = ['Client', 'ManagedBy', 'LicenceEndDate', 'ClientName', 'installation', 'LinkedInAccount']
    const customLabels: Array<{ key: string, value: string }> = []

    if (this.props.vpsDetails !== null) {
      this.setState({ hostname: this.props.vpsDetails.name })
      this.setState({ labels: this.props.vpsDetails.labels })
    }

    if (this.props.vpsDetails?.labels !== null && this.props.vpsDetails?.labels !== undefined) {
      Object.keys(this.props.vpsDetails?.labels).forEach((key, i) => {
        if (!HIDDEN_LABELS.includes(key)) {
          const label = { key, value: this.props.vpsDetails?.labels[key] }
          // @ts-expect-error
          customLabels.push(label)
        }
      })

      this.setState({ newFields: customLabels })
    }
  }

  validateLabels (labels: object): boolean {
    let valid = true

    Object.keys(labels).forEach((key: string) => {
      if (key.match('^[a-zA-Z0-9]{2,32}$') === null) {
        this.setState({ errorMessage: `key: ${key} is not allowed, no spaces or special characters allowed.\n 3-32 characters required.` })
        valid = false
      }
      // @ts-expect-error
      if (labels[key].match('^[a-zA-Z0-9]{2,32}$') === null) {
        // @ts-expect-error
        this.setState({ errorMessage: `value: ${labels[key]} is not allowed on key: ${key}, no spaces or special characters allowed.\n 3-32 characters required.` })
        valid = false
      }
    })

    return valid
  }

  async submitServer (): Promise<void> {
    if (this.state.hostname === '') {
      this.setState({ errorMessage: 'Hostname is required' })
      return
    }
    let labels = this.state.labels
    this.state.newFields.forEach((label) => {
      if (label.key !== '' && label.value !== '') {
        labels = {
          ...labels,
          [label.key]: label.value
        }
      }
    })
    if (this.validateLabels(labels)) {
      this.setState({ createRequest: true, errorMessage: null })
      if (this.props.update) {
        if (this.props.vpsDetails === null) {
          return
        }
        const vpsDetails = this.props.vpsDetails
        vpsDetails.name = this.state.hostname
        // @ts-expect-error
        vpsDetails.labels = labels

        this.vpsService.updateVpsServer(this.props.vpsDetails.id, { hostName: this.state.hostname, labels }).then(response => {
          if (response.status === 200) {
            this.props.setVpsDetails(vpsDetails)
            this.props.toggleForm()
            this.setState({ createRequest: false })
          }
        }).catch(console.log)
      } else {
        const customer = await this.customerService.getActiveCustomer()
        if (customer === null) return
        this.vpsService.createVpsServer(this.state.hostname, labels, this.props.serverType).then(response => {
          if (response.status === 200) {
            this.vpsService.getAllVpsServers().then(servers => {
              this.props.toggleForm()
              this.setState({ createRequest: false, newFields: [] })
              this.props.setLinkedHelperVpsServers(servers)
            }).catch(console.log)
          }
        }).catch(console.log)
      }
    }
  }

  handleChange (e: ChangeEvent<HTMLInputElement>): void {
    this.setState({ hostname: e.target.value })
  }

  setLabel (key: string, value: string): void {
    this.setState({
      labels: {
        ...this.state.labels,
        [key]: value
      }
    })
  }

  addLabelField (): void {
    this.setState({ newFields: [...this.state.newFields, { key: '', value: '' }] })
  }

  removeLabelField (index: number): void {
    const deletedField = this.state.newFields.splice(index, 1)
    const key = deletedField[0].key
    // @ts-expect-error
    delete this.state.labels[key]

    console.log(deletedField, this.state.labels)
    this.setState({ newFields: [...this.state.newFields] })
  }

  render (): JSX.Element {
    return (
            <>
                {
                    this.state.errorMessage !== null
                      ? <Alert variant={'danger'}>
                            {/* Wrong credentials! */}
                            {this.state.errorMessage}
                        </Alert>
                      : null
                }
                <div className="card">
                    <div className="header">
                        <h2> { this.props.title } </h2>
                    </div>

                    <div className="body">
                        <label>Hostname: </label>
                        <div className="input-group mb-3">
                            <input
                                name="hostname"
                                aria-describedby="basic-addon1"
                                aria-label="Hostname"
                                className="form-control"
                                placeholder="Hostname"
                                defaultValue={this.props.vpsDetails?.name}
                                type="text"
                                onChange={this.handleChange}
                                required
                            />
                        </div>
                        <label>Custom Label: </label><br/>
                        {this.state.newFields.map((field, index) => {
                          return (
                                <div key={index} className="form-group mb3">
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            placeholder="Label Name"
                                            pattern="^\S+\w{8,32}\S{1,}"
                                            title="No spaces or special characters allowed"
                                            type="text"
                                            defaultValue={field.key}
                                            onChange={(e) => {
                                              const newFields: Array<{ key: string, value: string }> = this.state.newFields
                                              newFields[index].value = e.target.value
                                              this.setState({ newFields })
                                            }}
                                        />
                                        <input
                                            className="form-control"
                                            placeholder="Label Value"
                                            pattern="^\S+\w{8,32}\S{1,}"
                                            title="No spaces or special characters allowed"
                                            type="text"
                                            defaultValue={field.value}
                                            onChange={(e) => {
                                              const newFields: Array<{ key: string, value: string }> = this.state.newFields
                                              newFields[index].value = e.target.value
                                              this.setState({ newFields })
                                            }}
                                        />
                                    </div>
                                    <a role='button' onClick={() => {
                                      this.removeLabelField(index)
                                    }} className="link-danger">Remove field</a>
                                </div>
                          )
                        })}
                        {
                            this.state.newFields.length >= 5
                              ? null
                              : <a role='button' onClick={this.addLabelField} className="link-info"
                                   style={{ marginTop: '20px' }}> Add custom label</a>
                        }
                        <br/><br/>
                        <Button onClick={this.submitServer} disabled={this.state.createRequest} variant="primary">
                            Submit
                        </Button>
                        {
                            this.props.update
                              ? <Button onClick={this.props.toggleForm} disabled={this.state.createRequest} variant="danger">
                                    Cancel
                                </Button>
                              : null
                        }

                    </div>
                </div>
            </>
    )
  }
}

// @ts-expect-error
const mapStateToProps = ({ vpsReducer }): { vpsDetails: VpsData | null, vpsServers: VpsData[] | null } => ({
  vpsDetails: vpsReducer.vpsDetails,
  vpsServers: vpsReducer.vpsServers
})

export default connect(mapStateToProps, {
  setVpsDetails,
  setLinkedHelperVpsServers
// @ts-expect-error
})(VpsLabelForm)
