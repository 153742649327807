import {
  ON_LOGGED_IN,
  ON_LOCKED,
  ON_ERROR,
  SET_ERROR_MESSAGE,
  ON_SUCCESS,
  SET_SUCCESS_MESSAGE
} from '../actions'

const initialState = {
  email: '',
  password: '',
  isLoggedIn: null,
  isLocked: false,
  isError: false,
  errorMessage: null,
  isSuccess: false,
  successMessage: null
}

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_LOGGED_IN: {
      return {
        ...state,
        isLoggedIn: action.payload
      }
    }

    case ON_LOCKED: {
      return {
        ...state,
        isLocked: action.payload
      }
    }

    case ON_ERROR: {
      return {
        ...state,
        isError: action.payload
      }
    }

    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.payload
      }
    }

    case ON_SUCCESS: {
      return {
        ...state,
        isSuccess: action.payload
      }
    }

    case SET_SUCCESS_MESSAGE: {
      return {
        ...state,
        successMessage: action.payload
      }
    }

    default:
      return state
  }
}

export default loginReducer
