import React from 'react'
import QuoteData from '../../Models/QuoteData'
import QuoteService from '../../Services/QuoteService'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import { setQuoteDetails } from '../../actions'
import ListTileSwitch from '../../components/Overview/ListTileSwitch'

interface PropTypes {
  quoteDetails: QuoteData | null
  setQuoteDetails: (val: QuoteData | null) => {}
}

interface StateTypes {
  quotes: QuoteData[] | null
  listFormat: boolean
}

class QuoteOverview extends React.Component<PropTypes, StateTypes> {
  private readonly quoteService: QuoteService

  constructor (props: PropTypes) {
    super(props)

    this.state = {
      quotes: null,
      listFormat: true
    }

    this.quoteService = new QuoteService()
  }

  componentDidMount (): void {
    this.quoteService.getQuotesFromCustomer().then((quotes: QuoteData[]) => {
      this.quoteService.filterRecentQuote(quotes).then((filteredQuotes: QuoteData[]) => {
        this.setState({ quotes: filteredQuotes })
      }).catch(console.log)
    }).catch(console.log)
  }

  saveQuoteData (quote: QuoteData): void {
    this.props.setQuoteDetails(quote)
  }

  renderList (): JSX.Element {
    return (
            <div className="body table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        <th>DATE</th>
                        <th>DISCOUNT</th>
                        <th>QUOTATION PERIOD</th>
                        <th>TOTAL</th>
                        <th>CURRENCY</th>
                        <th>SAVE NAME</th>
                        <th>OVERWRITE IF EXISTS</th>
                        <th>...</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.quotes?.map((quote, i) => {
                      return (
                            <tr key={i}>
                                <td>{new Date(quote.createdOn).toISOString()}</td>
                                <td>{quote.discount} - {quote.discountType}</td>
                                <td>{quote.quotationPeriod}</td>
                                <td>{quote.totalAmount}</td>
                                <td>{quote.currency}</td>
                                <td>{quote.saveName}</td>
                                <td>{quote.overwriteIfExist ? 'Yes' : 'No'}</td>
                                <td><Link to='/finance/quotes/details'><button onClick={() => { this.saveQuoteData(quote) }} className='btn btn-primary'>More details</button></Link></td>
                            </tr>
                      )
                    })}
                    </tbody>
                </table>
            </div>
    )
  }

  renderTiles (): JSX.Element {
    return (
            <>
                <div className="row" >
                    { this.state.quotes?.map((quote, index) => {
                      return (
                            <div className="col-xl-4 col-lg-6 col-md-12" key={index}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>{index + 1}</Card.Title>
                                        <Card.Text>
                                            <strong>Date:</strong> {new Date(quote.createdOn).toISOString()}
                                            <br />
                                            <strong>Discount: </strong>
                                            <span>{ quote.discount ?? 'not available' }</span>
                                            <br />
                                            <strong>Quotation Period: </strong>
                                            <span>{ quote.quotationPeriod ?? 'not available' }</span>
                                            <br />
                                            <strong>Total: </strong>
                                            <span>{ quote.totalAmount ?? 'not available' }</span>
                                            <br />
                                            <strong>Currency: </strong>
                                            <span>{ quote.currency ?? 'not available' }</span>
                                            <br />
                                            <strong>Save Name: </strong>
                                            <span>{ quote.saveName ?? 'not available' }</span>
                                            <br />
                                            <strong>Overwrite If Exist: </strong>
                                            <span>{ quote.overwriteIfExist ? 'Yes' : 'No' }</span>
                                            <br />
                                        </Card.Text>
                                        <Link to='/finance/quotes/details'>
                                            <button onClick={() => { this.saveQuoteData(quote) }} className='btn btn-primary'>
                                                More details
                                            </button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </div>
                      )
                    })}
                </div>
            </>
    )
  }

  render (): JSX.Element {
    return (
            <div className="col-lg-12">
                <div className="card">
                    <div className="header d-flex justify-content-between">
                        <h2>
                            My Last Quotes
                            <small>
                                My quotes of the last 3 months
                            </small>
                        </h2>
                        <ListTileSwitch setList={() => { this.setState({ listFormat: true }) }} setTile={() => { this.setState({ listFormat: false }) }} />
                    </div>
                    {
                        this.state.listFormat
                          ? this.renderList()
                          : null
                    }
                </div>
                {
                    !this.state.listFormat
                      ? this.renderTiles()
                      : null
                }
            </div>
    )
  }
}

// @ts-expect-error
const mapStateToProps = ({ quoteReducer }): { quoteDetails: QuoteData | null } => ({
  quoteDetails: quoteReducer.quoteDetails
})

export default connect(mapStateToProps, {
  setQuoteDetails
// @ts-expect-error
})(QuoteOverview)
