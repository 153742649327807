import { Navigate, Route, Routes } from 'react-router-dom'

import Page403 from '../pages/Shared/Page403'
import Page404 from '../pages/Shared/Page404'
import Page500 from '../pages/Shared/Page500'
import Page503 from '../pages/Shared/Page503'
import Maintenance from '../pages/Shared/Maintenance'
import Login from '../pages/Auth/Login';
import Logout from '../pages/Auth/Logout'
import SignOut from '../pages/Auth/SignOut'

export default function UnAuthorized (): JSX.Element {
  return (
        <Routes>
            <Route path="/page403" element={<Page403 />} />
            <Route path="/page404" element={<Page404 />} />
            <Route path="/page500" element={<Page500 />} />
            <Route path="/page503" element={<Page503 />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signout" element={<SignOut />} />
            <Route path="/logout" element={<Logout />} />

            <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
  )
}
