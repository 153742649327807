import React from 'react';
import Loading from '../../pages/Screens/Loading';
import UserData from '../../Models/UserData';
import { connect } from 'react-redux';
import { onCurrentUserChange } from '../../actions';
import AccountService from '../../Services/AccountService';

interface PropTypes {
  children: React.ReactElement;
  currentUser: UserData | null;
  onCurrentUserChange: (userData: UserData) => void;
}

interface StateTypes {
  initialRender: boolean;
}

class UserProvider extends React.Component<PropTypes, StateTypes> {

  constructor(props: PropTypes | Readonly<PropTypes>) {
    super(props);

    this.state = {
      initialRender: false
    };
  }

  componentDidMount(): void {
    this.setState({
      initialRender: true
    });
  }

  componentDidUpdate(prevProps: Readonly<PropTypes>, prevState: Readonly<StateTypes>): void {
    if (this.state.initialRender === prevState.initialRender && !this.state.initialRender) {
      return;
    }

    if(this.props.currentUser !== null) {
      return;
    }


    const loggedIn = AccountService.hasUserInfo()

    if(loggedIn){
      const user = AccountService.getCurrentUserFromLocalStorage()
      if(user){
        this.props.onCurrentUserChange(user)
      }
    } else {
      const accountService = new AccountService();
      accountService.getCurrentUserFromApi().then((user) => {
        this.props.onCurrentUserChange(user)
      }).catch((error) => {
        console.log({error})
      })
    }



  }

  render(): JSX.Element {
    if (!this.state.initialRender || this.props.currentUser === null) {
      return <Loading />;
    }


    return this.props.children;
  }
}

// @ts-ignore
const mapStateToProps = ({ userReducer }): { currentUser: UserData | null } => ({
  currentUser: userReducer.currentUser
})

export default connect(mapStateToProps, {
  onCurrentUserChange
})(UserProvider)
