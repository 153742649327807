import React from 'react'
import ActiveSubscriptionData from '../../Models/ActiveSubscriptionData'
import ActiveSubscriptionService from '../../Services/ActiveSubscriptionService'

interface StateTypes {
  activeSubscriptions?: ActiveSubscriptionData[] | null
}

class ActiveSubscriptions extends React.Component<any, StateTypes> {
  private readonly activeSubscriptionService: ActiveSubscriptionService

  constructor (props: any) {
    super(props)

    this.state = {
      activeSubscriptions: null
    }

    this.activeSubscriptionService = new ActiveSubscriptionService()
  }

  componentDidMount (): void {
    this.activeSubscriptionService.getActiveSubscriptions().then((activeSubscriptions: ActiveSubscriptionData[]) => {
      this.setState({ activeSubscriptions })
    }).catch(console.log)
  }

  render (): JSX.Element {
    return (
            <div className="col-lg-12">
                <div className="card">
                    <div className="header">
                        <h2>
                            Services
                            <small>
                                My Active Subscriptions
                            </small>
                        </h2>
                    </div>
                    <div className="body table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>SUBSCRIPTION NAME</th>
                                <th>SERVICE NAME</th>
                                <th>PRICE</th>
                                <th>PERIOD</th>
                                <th>START DATE</th>
                                <th>END DATE</th>
                                <th>NEXT PAYDATE</th>
                                <th>AUTO RENEW</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.activeSubscriptions?.map((service, i) => {
                              return (
                                <tr key={i}>
                                    <th scope="row">{i}</th>
                                    <td>{service.subscription?.name}</td>
                                    <td>{service.domain?.domainName ?? service.webHosting?.hostingName ?? service.mailHosting?.hostingName }</td>
                                    <td>{service.subscription?.price}</td>
                                    <td>{service.subscription?.period}</td>
                                    <td>{service.startDate}</td>
                                    <td>{service.endDate ?? '-'}</td>
                                    <td>{service.renewalDate}</td>
                                    <td>{service.autoRenew ? 'Yes' : 'No'}</td>
                                </tr>
                              )
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className="header">
                        <h2>
                            Products
                            <small>
                                My Purchased Products
                            </small>
                        </h2>
                    </div>
                    <div className="body table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>PRODUCT NAME</th>
                                <th>PRODUCT SUPPLIER</th>
                                <th>QUANTITY</th>
                                <th>BRAND</th>
                                <th>PRICE</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Macbook Pro &quot;16 (2019)</td>
                                <td>Apple</td>
                                <td>5</td>
                                <td>Apple</td>
                                <td>1999,-</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    )
  }
}

export default ActiveSubscriptions
