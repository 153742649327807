import WebHostingData from '../Models/WebHostingData'
import ApiService from './ApiService'

class WebHostingService {
  private readonly api: ApiService

  constructor () {
    this.api = new ApiService()
  }

  getWebHostingByDomain (domainUuid: string): Promise<WebHostingData | null> {
    return new Promise((resolve, reject) => {
      this.api.apiCall(`/api/v1/domain/${domainUuid}/webhosting`).then(response => {
        if (response.status === 200) {
          return resolve(response.data)
        }
        return resolve(null)
      }).catch(reject)
    })
  }
}

export default WebHostingService
