import UserData from '../Models/UserData'

class FeatureFlagService {
  public static checkForVPSFeatureFlag (currentUser: UserData): boolean {
    return FeatureFlagService.checkForFeatureFlag(currentUser, 'FEATURE_SCHURQ_LINKEDHELPER')
  }

  public static checkForFeatureFlag (currentUser: UserData, featureFlag: string): boolean {
    if (!currentUser || currentUser.roles === undefined) {
      return false
    }
    if (currentUser.roles.length === 0) {
      return false
    }
    return currentUser.roles.includes(featureFlag)
  }
}

export default FeatureFlagService
