import {
  SET_DOMAIN_DETAILS
} from '../actions'
import DomainData from '../Models/DomainData'

interface DomainReducerState {
  domainDetails: DomainData | null
}

const initialState: DomainReducerState = {
  domainDetails: null
}

interface Action {
  type: string
  payload: DomainData
}

const domainReducer = (state = initialState, action: Action): DomainReducerState => {
  switch (action.type) {
    case SET_DOMAIN_DETAILS: {
      return {
        ...state,
        domainDetails: action.payload
      }
    }

    default:
      return state
  }
}

export default domainReducer
