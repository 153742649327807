import React from 'react'
import UIBaseModal from './UIBaseModal'
import AccountService from '../../Services/AccountService'
import { connect } from 'react-redux'
import { onActiveCustomerChange, onHideCloseButton, onModalShow } from '../../actions'
import CustomerForm from '../Form/CustomerForm'
import CustomerFormData from '../../Models/CustomerFormData'
import CustomerService from '../../Services/CustomerService'
import CustomerData from '../../Models/CustomerData'
import ToolsService from '../../Services/ToolsService'

interface PropTypes {
  title: string
  closeButtonText: string
  primaryButtonText: string
  bodyText?: string
  size?: 'sm' | 'lg' | 'xl'
  activeCustomer: CustomerData | null
  onActiveCustomerChange: (activeCustomer: CustomerData) => {}
}

interface StateTypes {
  formData: CustomerFormData
  show: boolean
  hideCloseButton: boolean
  disableCreateButton: boolean
  errorMessage?: string
}

class CustomerCreateModel extends React.Component<PropTypes, StateTypes> {
  private readonly customerService: CustomerService
  private readonly accountService: AccountService

  constructor (props: PropTypes) {
    super(props)

    this.state = {
      formData: {
        companyName: '',
        kvkNumber: '',
        street: '',
        streetNumber: '',
        postalCode: '',
        city: '',
        countryCode: '',
        phoneNumber: '',

        administrationFirstName: '',
        administrationInsertion: '',
        administrationLastName: '',

        email: '',

        hetznerApiKey: null
      },
      show: false,
      hideCloseButton: false,
      disableCreateButton: false,
      errorMessage: undefined
    }

    this.customerService = new CustomerService()
    this.accountService = new AccountService()

    this.showModal = this.showModal.bind(this)
    this.hideCloseButton = this.hideCloseButton.bind(this)
    this.userHasCustomers = this.userHasCustomers.bind(this)
    this.setFormData = this.setFormData.bind(this)
    this.createCustomerApiCall = this.createCustomerApiCall.bind(this)
  }

  componentDidMount (): void {
    this.userHasCustomers().then(hasCustomers => {
      this.showModal(!hasCustomers)
    }).catch(console.log)
    this.hideCloseButton(true)
  }

  showModal (show: boolean): void {
    this.setState({ show })
  }

  hideCloseButton (hide: boolean): void {
    this.setState({ hideCloseButton: hide })
  }

  async userHasCustomers (): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      const accountService = new AccountService()
      const customers = accountService.getCustomersFromUser()

      return resolve(customers !== undefined)
    })
  }

  setFormData (data: CustomerFormData): void {
    data.hetznerApiKey = null
    this.setState({ formData: data })
  }

  getFormData (): CustomerFormData {
    return this.state.formData
  }

  validateForm (formData: CustomerFormData): boolean {
    if (!/\d{8}/.test(formData.kvkNumber.trim())) {
      this.setState({ errorMessage: 'KVK-number must be 8 digits' })
      return false
    }
    if (!/^[1-9][0-9]{3}\s?[A-Za-z]{2}$/i.test(formData.postalCode)) {
      this.setState({ errorMessage: 'Invalid Postal code' })
      return false
    }

    return true
  }

  async createCustomerApiCall (): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      const customerData = this.getFormData()
      if (this.validateForm(customerData)) {
        this.customerService.createCustomer(customerData).then((response: boolean) => {
          this.accountService.getCurrentUserFromApi().then(user => {
            if (user.customers !== undefined) {
              const customers = this.accountService.getCustomersFromUser();
              if (customers != null) {
                const customer: CustomerData = customers[0]
                this.customerService.storeActiveCustomer(customer)
                this.props.onActiveCustomerChange(customer)
              }

              this.showModal(false)

              // @todo fix this hotfix in ticket BHP-300
              ToolsService.navigateTo('/dashboard')
            }
          }).catch(reject)
          return resolve(response)
        }).catch(reject)
      }
    })
  }

  render (): JSX.Element {
    const { title, closeButtonText, primaryButtonText, bodyText } = this.props
    const { errorMessage, show, hideCloseButton, disableCreateButton } = this.state

    return (
      <UIBaseModal
        title={title}
        closeButtonText={closeButtonText}
        primaryButtonText={primaryButtonText}
        bodyText={errorMessage === undefined ? bodyText : errorMessage}
        bodyElement={<CustomerForm sendData={this.setFormData}/>}
        onSave={this.createCustomerApiCall}
        show={show}
        isHidden={hideCloseButton}
        isDisabled={disableCreateButton}
      />
    )
  }
}

// @ts-expect-error
const mapStateToProps = ({ customerReducer, modalReducer }): { activeCustomer: CustomerData | null, show: boolean, isHidden: boolean, isDisabled: boolean } => ({
  activeCustomer: customerReducer.activeCustomer,
  show: modalReducer.show,
  isHidden: modalReducer.isHidden,
  isDisabled: modalReducer.isDisabled
})

export default connect(mapStateToProps, {
  onModalShow,
  onHideCloseButton,
  onActiveCustomerChange
// @ts-expect-error
})(CustomerCreateModel)
