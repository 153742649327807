import React from 'react';
import Layout from '../../components/public/Layout';
import { useLogto } from '@logto/react';
import AuthService from '../../Services/AuthService';

const Login = () => {
  const {signIn, isAuthenticated, isLoading} = useLogto();
  const title = 'Login to your account';
  const loaded = (isLoading === undefined ? false : isLoading);
  const authConfig = new AuthService().getAuthConfig();


  const loginButton = <button
      onClick={() => signIn(`${window.location.origin}${authConfig.redirectUri}`)}
        className="btn btn-primary btn-lg btn-block"
        >Sign In</button>;
  return (
      <Layout
          title={title}
          forgotPassword={true}
          register={true}
          login={false}
          forgotPasswordLogin={false}
          loading={loaded}
          children={isAuthenticated ? <></> : loginButton}
      />
  );

};
export default Login;
